import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Modal, TextField, Stack, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { styled } from '@mui/system';

//styles
import useStyles from './index.style';

import Label from '../../../custom-styled/label';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

const MAX_CHAR_LIMIT = 120;

const TextFieldWrapper = styled('div')({
    position: 'relative',
});

const CharacterCount = styled(Typography)(({ theme, charRemaining }) => ({
    position: 'absolute',
    bottom: 8,
    right: 10,
    fontSize: '0.75rem',
    color: '#EB3917',
    pointerEvents: 'none',
}));

const AnswerExplainationModal = ({ open, handleClose, handleSave, modalInputText, handleChange, textValue, inputTitle, heading }) => {
    const classes = useStyles();
    const [charRemaining, setCharRemaining] = useState(MAX_CHAR_LIMIT);

    useEffect(() => {
        setCharRemaining(MAX_CHAR_LIMIT - modalInputText.length);
    }, [modalInputText]);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description" sx={{ zIndex: 13001 }}>
            <Box sx={style}>
                <div className={classes.headerDiv}>
                    <Label labelText={heading} fs='1rem' style={{ marginBottom: '10px' }} />
                    <IconButton className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon style={{ color: '#ff5722', fontSize: "20px" }} />
                    </IconButton>
                </div>

                <Stack spacing={2} sx={{ margin: '1rem 0' }}>
                    <TextFieldWrapper>
                        <TextField
                            label={inputTitle}
                            variant="outlined"
                            multiline
                            minRows={3}
                            fullWidth
                            value={modalInputText}
                            onChange={(e) => handleChange(e.target.value)}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    '&.Mui-focused': { color: '#f4511e' },
                                },
                                '& .MuiOutlinedInput-root': {
                                    minHeight: '100px',
                                    padding: '23.5px 14px',
                                    '& fieldset': { borderColor: '#f4511e' },
                                    '&:hover fieldset': { borderColor: '#f4511e' },
                                    '&.Mui-focused fieldset': { borderColor: '#f4511e' },
                                },
                            }}
                        />
                        <CharacterCount charRemaining={charRemaining}>
                            {charRemaining < 0
                                ? `Exceeded by ${Math.abs(charRemaining)} characters`
                                : `${charRemaining} characters remaining`}
                        </CharacterCount>
                    </TextFieldWrapper>

                    <div className={classes.buttonContainerDiv}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSave(modalInputText)}
                            disabled={charRemaining < 0}
                            style={{
                                backgroundColor: charRemaining < 0 ? '#ccc' : '#f4511e',
                                cursor: charRemaining < 0 ? 'not-allowed' : 'pointer',
                                border: charRemaining < 0 ? 'none' : '1px solid #f4511e',
                                textTransform: 'capitalize',
                                width: "44%"
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </Stack>
            </Box>
        </Modal>
    );
};

export default AnswerExplainationModal;
