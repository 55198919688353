import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import styled from 'styled-components';

const TextInput = forwardRef((props, ref) => {
  let inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current.focus();
    },
  }));

  let type = 'text';

  if (props.hasOwnProperty('type')) {
    type = props.type;
  }

  let value = '';

  if (props.hasOwnProperty('value')) {
    value = props.value;
  }

  let errorText = null;

  if (props.hasOwnProperty('errorText')) {
    errorText = props.errorText;
  }

  let disabled = false;

  if (props.hasOwnProperty('disabled')) {
    disabled = props.disabled;
  }

  let placeholder = '';

  if (props.hasOwnProperty('placeholder')) {
    placeholder = props.placeholder;
  }

  function handleInput() {
    if (inputRef.hasOwnProperty('current') && inputRef.current !== null) {
      if (props.hasOwnProperty('onChangeText')) {
        props.onChangeText(inputRef.current.value);
      }
    }
  }

  let style = {};

  if (props.hasOwnProperty('style')) style = { ...style, ...props.style }


  return (
    <div style={style}>
      <Box width={props.width} border={props.border}>
        <StyledInput
          ref={inputRef}
          type={type}
          onChange={handleInput}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
        />
      </Box>
      {errorText !== null ? <ErrorText>{errorText}</ErrorText> : null}
    </div>
  );
});

const Box = styled.div`
  width: ${(props) => (props.width ? props.width : '320px')};
  max-width: 100%;
  height: 32px;
  border-radius: 4px;
  border: ${(props) => (props.border ? props.border : 'solid 0.5px #dde0e2')};
  background-color: #ffffff;
  display: flex;
  align-items: center;
`;

const ErrorText = styled.span`
  font-size: 0.7rem;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #f63b34;
  margin-top: 5px;
`;

export const StyledInput = styled.input`
  border: none;
  width: 100%;
  background-color: transparent;
  display: block;
  height: 100%;
  margin: 0;
  padding-left: 10px;
  sfont-family: 'Open Sans';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;

  &:focus {
    border: none;
    outline: 0 !important;
  }

  %:active {
    border: none;
  }
`;

export default TextInput;
