import isEmpty from "./isEmpty";
import textToSpeechServices from '../services/text-to-speech';
import challengesServices from '../services/challenges';

const getContextList = (list) => {
    let contexts = [];
    list.forEach(ele => {
        if (ele.contextObj.context.length) contexts.push(ele.contextObj);
    })
    return contexts
}

const getTextToSpeechUrl = async (contexts, designType, companyId, microskillId, challengeId) => {
    if (designType === "MTRR_PopcornMatch" || designType === "TF_TastyTruth" || designType === "TF_BuildingBlocks" || designType === "TF_CrazyCars" || designType === "MA_SpaceRocks") {

        let newContexts = [];

        for (let i = 0; i < contexts.length; i++) {
            let questionText;
            if (designType === "MTRR_PopcornMatch") {
                questionText = contexts[i].context;
            } else {
                questionText = contexts[i].question;
            }
            let audioUrl = isEmpty(contexts[i]?.speechUrl) ? "" : contexts[i]?.speechUrl;  // Initialize audioUrl to an empty string
            if (questionText.length && isEmpty(contexts[i].speechUrl)) {
                const response = await textToSpeechServices.convertTextToSpeech({ text: questionText });
                if (response.status === 200) {
                    const blob = new Blob([response.data], { type: 'audio/wav' });
                    const file = new File([blob], 'text-to-speech.wav', { type: 'audio/wav' });

                    let formData = await jsonToFormData({ companyId, microskillId, challengeId, LAMediaFiles: file });
                    let res = await challengesServices.uploadMediaResources(formData);

                    if (res.status === 200 || res.status === 201) {
                        audioUrl = res.data.data.mediaResources[0].location;
                    }
                }
            }
            newContexts.push({ ...contexts[i], speechUrl: audioUrl });
        }

        return newContexts;
    } else {
        return contexts;
    }
};


const getSequenceUpdated = (list) => {
    list.forEach((ele, i) => {
        ele.sequence = i + 1
    })
    return list
}

const getNewListMTRR = (list) => {
    let tempList = [];
    list.forEach((element, i) => {
        let ele = element.questionArr;
        for (let j = 0; j < ele.length; j++) {
            ele[j].contextId = element.contextObj.id;
            ele[j].skillId = element.skillId;
            tempList.push(ele[j]);
        }
    })
    let newList = []
    for (let k = 0; k < tempList.length; k++) {
        let temp = { ...tempList[k], id: k }
        newList.push(temp)
    }

    return newList;
}


const getNewListMTRREdit = (list) => {
    let tempList = [];
    list.forEach((element, i) => {
        let ele = element.questionArr;
        for (let j = 0; j < ele.length; j++) {
            ele[j].contextId = element.contextObj.id;
            ele[j].skillId = element.skillId;
            tempList.push(ele[j]);
        }
    })

    let newList = []
    for (let k = 0; k < tempList.length; k++) {
        let temp = tempList[k]
        if (typeof (tempList[k].id) === 'number') {
            temp = { ...tempList[k], id: k }
        }
        let tempQuestion = {
            id: temp.id,
            contextId: temp.contextId,
            question: temp.question,
            skillId: temp.skillId,
            answers: [{
                id: temp.answers[0].id,
                option: temp.answers[0].option,
                value: temp.answers[0].value
            }]
        }
        newList.push(tempQuestion)
    }

    return newList;
}


const getValidationErrors = (listItem) => {
    if (listItem.hasOwnProperty('validations')) {
        if (listItem['validations'].length > 1) {
            let numberOfErrors = listItem['validations'].length;
            return `${numberOfErrors} errors`
        } else {
            return `${listItem['validations'][0]}`
        }
    } else {
        return
    }


}

const formatBytes = (bytes, decimals = 2) => {
    if (!bytes) return '0 KB'
    if (!Number.isInteger(bytes)) return `${bytes} KB`
    if (!+bytes) return '0 KB'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const base64toFile = (dataurl, filename = 'temp.png') => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            resolve(reader.result);
        };
        reader.onerror = function (error) {
            reject('Error: ' + error);
        };
    });
}

const imageCompressor = async (file) => {
    const compressedImgMimeType = "image/webp";
    const base64 = await fileToBase64(file);
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.src = base64;
        img.onload = function () {
            let definedWidth = 800;
            let imageWidth = img.width;
            let imageHeight = img.height;
            let scaleFactor = definedWidth / imageWidth;
            let elem = document.createElement('canvas');
            elem.width = imageWidth;
            elem.height = imageHeight
            if (imageWidth > definedWidth) {
                elem.width = definedWidth;
                elem.height = imageHeight * scaleFactor;
            }
            let data = base64
            let ctx = elem.getContext('2d');
            ctx.drawImage(img, 0, 0, elem.width, elem.height);
            let imageSizeInKb = (base64.length * 0.75) / 1024;
            // console.log("actual size:", imageSizeInKb);
            if (imageSizeInKb > 50) {
                data = ctx.canvas.toDataURL(compressedImgMimeType, 1);
            } else {
                data = ctx.canvas.toDataURL(compressedImgMimeType, 1);
            }
            let fileData = base64toFile(data, file.name);
            resolve(fileData);
        }
        // console.log("base64:", data);
        img.onerror = function (error) {
            reject('Error: ' + error);
        }
    });
}

const ignoreCompressionFor = ["image/gif"];
const getUploadMediaPayload = async (companyId, microskillId, challengeId, fieldName, file, compressImages = true) => {
    let compressedFile = file;
    if (!ignoreCompressionFor.includes(file.type) && compressImages) compressedFile = await imageCompressor(file);
    let formData = new FormData();
    formData.append('companyId', companyId);
    formData.append('microskillId', microskillId);
    formData.append('challengeId', challengeId);
    formData.append(fieldName, compressedFile);
    return formData;
}

const jsonToFormData = async (json, compressImages = true) => {
    let formData = new FormData();
    for (const key in json) {
        let value = json[key];
        if (Array.isArray(value) && typeof value[0] === 'object') value = JSON.stringify(value);
        if (compressImages && typeof value === 'object' && value?.type?.includes("image/") && !ignoreCompressionFor.includes(value.type)) {
            value = await imageCompressor(value);
        }
        formData.append(key, value);
    }
    return formData;
}

const shuffleArray = (array) => {
    let currentIndex = array.length, randomIndex;
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

const imageUrlToBase64 = async (imageUrl, callback) => {
    imageUrl = `${imageUrl}?bypasscor=xx`
    const fetchedImage = await fetch(imageUrl);
    if (fetchedImage.status !== 200) {
        callback(imageUrl);
        return
    }
    const blob = await fetchedImage.blob();
    const reader = new FileReader();
    reader.onloadend = () => {
        let base64 = reader.result;
        base64 = base64.split(';');
        base64[0] = "data:image/png;";
        base64 = base64.join('')
        callback(base64)
    };
    reader.readAsDataURL(blob);
}

//TMS UTILS

function objectify(records, mapping_key) {
    let o = {};

    if (!Array.isArray(records)) return o;

    if (records.length === 0) return o;

    for (let record of records) {
        let key = record[mapping_key];
        o[key] = record;
    }

    return o;
}

function syncTimer(comp_id) {
    let date = new Date();
    let hour = date.getHours() > 7 ? 7 : 12;
    let time = 'AM'

    if (comp_id === 100) {
        if (date.getHours() > 7 && date.getHours() < 14) {
            hour = 7;
        } else if (date.getHours() >= 14) {
            hour = 2;
            time = 'PM';
        } else {
            hour = 12;
        }
    }

    const Str =
        (hour) +
        ':' +
        '00 ' +
        time + ' ' +
        ('00' + date.getDate()).slice(-2) +
        '/' +
        ('00' + (date.getMonth() + 1)).slice(-2) +
        '/' +
        date.getFullYear();
    return Str;
}

function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

const remoteUrlToFilename = (url) => {
    let temp = url.split("/");
    return temp.at(-1)
}

const getSkillsArray = (list) => {
    let skills = [];
    list.forEach(element => {
        if (element.skillId.length) { //CHECKING IF SKILL IS SELECTED OR NOT.
            if (!skills.includes(element.skillId)) {//PUSHING ONLY UNIQUE SKILLS TO skills ARRAY 
                skills.push(element.skillId);
            }
        }
    })
    return skills
}

const getImageUrlFromContents = (contents) => {
    let url = null;
    contents.forEach(element => {
        if (element.name === 'IMAGE' && element.type === 'URL') {
            url = element.content;
        }

    });
    return url;
}
const getVideoObjFromContents = (contents) => {
    let videoObj = {};
    contents.forEach(element => {
        if ((element.name === 'VIDEO' || element.name === 'YOUTUBE') && element.type === 'URL') {
            videoObj = element;
        }

    });
    return videoObj;
}
const getTitleFromContents = (contents) => {
    let title = null;
    contents.forEach(element => {
        if (element.name === 'TITLE' && element.type === 'TEXT') {
            title = element.content;
        }

    });
    return title;
}
const getBodyFromContents = (contents) => {
    let body = null;
    contents.forEach(element => {
        if (element.name === 'BODY' && element.type === 'TEXT') {
            body = element.content;
        }

    });
    return body;
}


const convertToEmbedUrl = (url) => {
    let id = ''
    let newUrl = `https://www.youtube.com/embed/`
    if (url.includes('watch')) {
        const query = url.split("?");
        const search = `?${query[1]}`;
        const urlParams = new URLSearchParams(search);
        id = urlParams.get('v');
    } else {
        id = url.split("/").at(-1)
    }

    newUrl = `${newUrl}${id}`
    return newUrl
}

const isValidDate = (dateString) => {
    if (typeof (dateString) !== "string" || dateString === "0") return false;
    const parsedDate = Date.parse(dateString);
    return !isNaN(parsedDate);
}

const getHeadings = (data = [{}], dateStampType = null) => {
    let allHeadings = [];
    for (let key in data[0]) {
        let heading = { title: key, key: key };
        let withBtn = ["Login Status", "Completion", "Completion Status", "Percentage Value", "Percentage"];
        if (withBtn.includes(key)) heading.isBtn = true;
        // let withDate = ["First Login At", "Latest Login At",];
        // if (withDate.includes(key) && isValidDate(data[0][key])) {
        //     heading.isDate = false;
        //     if (dateStampType) heading.dateStampType = dateStampType;
        // }
        allHeadings.push(heading)
    }
    return allHeadings;
}

const getFormattedDate = (date, separator = "/") => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedDate = dd + separator + mm + separator + yyyy;
    if (formattedDate.includes("NaN")) return date;
    return formattedDate;
}

function isFloat(number) {
    return !Number.isInteger(number);
}

function isValidEmail(email) {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

function getRandomNumber(till = 10) {
    return Math.floor(Math.random() * till) + 1;
}

function truncateString(str, maxLength = 30) {
    if (isEmpty(str)) return "";
    if (str.length <= maxLength) {
        return str;
    } else {
        return str.slice(0, maxLength) + '...';
    }
}

const getSelectLabel = ({ type, defaultText, selected, dataObject, labelLength = 0 }) => {
    let newLabel = defaultText || '';
    let maxLabelLen = type === 'single' ? 18 : 22;
    if (labelLength) maxLabelLen = labelLength
    if (type === 'single') {
        if (selected.length) newLabel = truncateString(dataObject[selected[0]]?.name, maxLabelLen);
        return newLabel
    }
    if (type === 'multiple') {
        if (selected.length) {
            newLabel = (selected.length > 1) ?
                `${truncateString(dataObject[selected[0]]?.name, maxLabelLen - 8)} & ${selected.length - 1} more` :
                truncateString(dataObject[selected[0]]?.name, maxLabelLen)
        }
        return newLabel
    }
    return newLabel
}


function scrollToBottom() {
    const element = document.documentElement; // Use the root element (usually <html>) for full-page scrolling
    element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
}

function removeInlineStyles(element) {
    if (element.nodeType === 1) { // Check if it's an element (Node.ELEMENT_NODE)
        element.removeAttribute('style'); // Remove the style attribute
    }

    if (element.hasChildNodes()) {
        const children = element.childNodes;
        for (let i = 0; i < children.length; i++) {
            removeInlineStyles(children[i]); // Recursively remove styles from child elements
        }
    }
}


function removeStyleAndHrefTagsFromString(inputString) {
    // Remove style attributes
    const stringWithoutStyle = inputString.replace(/style=".*?"/g, '');

    // Remove href attributes
    const stringWithoutStyleAndHref = stringWithoutStyle.replace(/href=".*?"/g, '');

    return stringWithoutStyleAndHref;
}


const getMaxLifeForGba = ({ designType, maxQuestion, questionsInGba, maxLife }) => {
    let life = 4;
    if (designType === 'TF_CrazyCars' || designType === 'TF_BuildingBlocks'
        || designType === 'TF_TastyTruth' || designType === 'TF_SoundRight'
        || designType === 'MCQ_TikiMonkey' || designType === 'MCQ_MasterBlaster' || designType === 'MCQ_Football') {
        life = Math.floor(maxLife * (questionsInGba / maxQuestion))

    } else if (designType === 'MA_BalloonPop' || designType === 'MA_SpaceRocks'
        || designType === 'MTRR_PopcornMatch' || designType === 'MTRR_TrashTrivia'
        || designType === 'MA_RevealThePicture') {
        life = Math.round(maxLife * (questionsInGba / maxQuestion))
    }
    return life
}

const getQuestionAndLifeForGba = ({ designType }) => {
    let data = { maxLife: 4, maxQuestion: 10 }
    if (designType === 'TF_CrazyCars' || designType === 'TF_BuildingBlocks'
        || designType === 'TF_TastyTruth' || designType === 'TF_SoundRight'
        || designType === 'MCQ_TikiMonkey') {
        data = { maxLife: 4, maxQuestion: 10 }
    } else if (designType === 'MTRR_PopcornMatch' || designType === 'MTRR_TrashTrivia') {
        data = { maxLife: 5, maxQuestion: 15 }
    } else if (designType === 'MA_BalloonPop' || designType === 'MA_SpaceRocks' || designType === 'MA_RevealThePicture') {
        data = { maxLife: 4, maxQuestion: 24 }
    }
    return data
}


function hexToFilter(hexCode) {
    // Ensure the hex code starts with #
    if (!hexCode.startsWith('#')) {
        hexCode = '#' + hexCode;
    }

    // Convert hex to RGB
    const r = parseInt(hexCode.slice(1, 3), 16);
    const g = parseInt(hexCode.slice(3, 5), 16);
    const b = parseInt(hexCode.slice(5, 7), 16);

    // Create the CSS filter string
    const cssFilter = `filter: grayscale(0) invert(100%) sepia(100%) saturate(10000%) hue-rotate(0deg) brightness(1) contrast(1);` +
        ` filter: grayscale(0) invert(100%) sepia(100%) saturate(10000%) hue-rotate(0deg) brightness(1) contrast(1) drop-shadow(0 0 10px rgba(${r}, ${g}, ${b}, 0.5));`;

    return cssFilter;
}

const filterBlackColorToOrange = `brightness(0) saturate(100%) invert(54%) sepia(95%) saturate(4979%) hue-rotate(351deg) brightness(100%) contrast(93%)`

const getCompanyIdInAzureFormat = (companyId = null) => {
    if (companyId) return companyId.toLowerCase().replace(/_/g, '-');
    return companyId;
}

const formatDate = (isoString) => {
    const date = new Date(isoString);

    // Extract components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    let hours = date.getUTCHours(); // Using UTC to align with 'Z' (Zulu time)
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';

    // Convert 24-hour time to 12-hour format
    hours = hours % 12 || 12;

    // Return formatted string
    return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
}

function replaceSubstring(newSubstring, oldSubstring, completeString) {
    if (isEmpty(oldSubstring) || !completeString.includes(oldSubstring)) {
        return completeString; // Return the original string if oldSubstring is not found
    }

    const regex = new RegExp(oldSubstring, 'g'); // Create a global regular expression for oldSubstring
    return completeString.replace(regex, newSubstring);
}


export default {
    getContextList,
    getNewListMTRR,
    getValidationErrors,
    formatBytes,
    base64toFile,
    getUploadMediaPayload,
    jsonToFormData,
    shuffleArray,
    objectify,
    capitalize,
    syncTimer,
    getNewListMTRREdit,
    remoteUrlToFilename,
    getSkillsArray,
    getImageUrlFromContents,
    getVideoObjFromContents,
    getTitleFromContents,
    getBodyFromContents,
    imageUrlToBase64,
    convertToEmbedUrl,
    getSequenceUpdated,
    isValidDate,
    getHeadings,
    getFormattedDate,
    isFloat,
    isValidEmail,
    getRandomNumber,
    truncateString,
    getSelectLabel,
    scrollToBottom,
    removeInlineStyles,
    removeStyleAndHrefTagsFromString,
    getMaxLifeForGba,
    getQuestionAndLifeForGba,
    hexToFilter,
    filterBlackColorToOrange,
    getTextToSpeechUrl,
    getCompanyIdInAzureFormat,
    formatDate,
    replaceSubstring

}