import React, { useState, useRef, useEffect, useContext } from 'react';
import styles, { FlexRowDiv } from './style';

//components
import TextInput from './components/TextInput';
import TextArea from './components/TextArea';
import FormControl from './components/FormControl';
// import Label from './components/Label';
import Label from '../custom-styled/label';
import FlatClearButton from './components/FlatClearButton';

import FlatPrimaryButton from './components/FlatPrimaryButton';
import SimpleFormSelect from './components/SimpleFormSelect';
import Editor from './Editor';
import ToggleSwitch from './components/ToggleSwitch';

//new
import Snackbar from '@mui/material/Snackbar';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

//services
import challengesServices from '../../services/challenges';

//constants
import { defaultPrompt, defaultPromptForPerformanceEvaluation, languages, objectives, teamEvaluationScenario, teamEvaluationScenarioFormatted, productScenario } from './constants';

//contexts
import { GlobalStates } from '../../App';

//utils
import commonUtil from '../../utils/commonUtil';
import isEmpty from '../../utils/isEmpty';


export const Page1_challange = React.memo(({
  isEdit,
  setCurrentPage,
  pages,
  microSkillState,
  setMicroSkillState,
  updateChallengeTitle,
  challengeTitleLimit,
}) => {
  const classes = styles();
  const quillRef = useRef();
  const firstRender = useRef(true);
  const [globalStates] = useContext(GlobalStates);

  const nextAction = () => {
    console.log('microSkillState page 1 = ', microSkillState);
    // goToNextPage();
    // return
    if (isEmpty(microSkillState.challengeName)) {
      globalStates.handleToast(true, 'Challenge title cannot be empty', 'error');
      return;
    }
    if (isEmpty(microSkillState.objective)) {
      globalStates.handleToast(true, 'Please select the Objective', 'error');
      return;
    }

    if (isEmpty(microSkillState.scenario)) {
      globalStates.handleToast(true, 'Scenario cannot be empty', 'error');
      return;
    }

    goToNextPage();
    return
  };

  const goToNextPage = () => {
    setCurrentPage(pages[1]);
  };

  const handleScenarioChange = (rtfValue, p2) => {//converts img to base64 

    if (!isEmpty(p2?.ops[0]?.insert) && p2?.ops[0]?.insert?.trim().toLowerCase() === teamEvaluationScenario.trim().toLowerCase()) return
    if (!isEmpty(p2?.ops[0]?.insert) && p2?.ops[0]?.insert?.trim().toLowerCase() === productScenario.trim().toLowerCase()) return

    if (isEdit && firstRender.current) {
      firstRender.current = false;
      return
    }

    // if (title.length === transcript.length) return
    let text = ''
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      text = editor.getText().trim();
    }
    let updatedMediaPath = '';
    if (isEdit && (text?.trim() === microSkillState.prevScenario?.trim())) {
      updatedMediaPath = microSkillState.prevMediaPath
    };
    setMicroSkillState({ ...microSkillState, scenario: text, formatted: rtfValue, scenarioMediaPath: updatedMediaPath });

  };

  const handleObjectiveChange = (values) => {

    let newMsState = { ...microSkillState, objective: values };
    const performanceId = 'performance evaluation of a team member';
    const productId = 'gain customer interest';
    if (values === performanceId) {
      let updatedMediaPath = '';
      if (isEdit && (teamEvaluationScenario?.trim() === microSkillState.prevScenario?.trim())) {
        updatedMediaPath = microSkillState.prevMediaPath
      };

      // let boundariesId = 2;
      // const newPrompt = defaultPromptForPerformanceEvaluation.map((v) => {
      //   if (v.id === boundariesId) {
      //     const updatedPrompt = commonUtil.replaceSubstring('manager', microSkillState.endUserRole, v.content);
      //     return { ...v, content: updatedPrompt };
      //   }
      //   return v;
      // });


      newMsState = {
        ...newMsState,
        scenario: teamEvaluationScenario,
        formatted: teamEvaluationScenarioFormatted,
        scenarioMediaPath: updatedMediaPath,
        personality: 'frontline sales executive',
        endUserRole: 'manager',
        prompt: defaultPromptForPerformanceEvaluation
      };
    } else if (values === productId) {
      let updatedMediaPath = '';
      if (isEdit && (productScenario?.trim() === microSkillState.prevScenario?.trim())) {
        updatedMediaPath = microSkillState.prevMediaPath
      };


      // let boundariesId = 2;
      // const newPrompt = defaultPrompt.map((v) => {
      //   if (v.id === boundariesId) {
      //     const updatedPrompt = commonUtil.replaceSubstring('life insurance agent', microSkillState.endUserRole, v.content);
      //     return { ...v, content: updatedPrompt };
      //   }
      //   return v;
      // });


      newMsState = {
        ...newMsState,
        scenario: productScenario,
        formatted: productScenario,
        scenarioMediaPath: updatedMediaPath,
        personality: 'working professional',
        endUserRole: 'life insurance agent',
        prompt: defaultPrompt
      };

    }
    console.log('newMsState = ', newMsState);

    setMicroSkillState(newMsState)

  }


  return (
    <>
      <FlexRowDiv className={classes.challangeContainer}>

        <div className={`${classes.leftContainer} scrollbar`}>
          {/* challange title */}
          <FormControl className={classes.formControlWrapper}>
            <Label labelText="Challenge Title" required={true} p="0 0 0.8rem 0" />
            <div
              style={{
                border: challengeTitleLimit < 0 ? 'solid 0.5px red' : 'solid 0.5px #dde0e2',
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}>
              <TextInput
                placeholder={'Enter Challenge Title Here'}
                border={'transparent'}
                width={'100%'}
                style={{ width: '72%' }}
                value={microSkillState.challengeName}
                onChangeText={updateChallengeTitle}
              // errorText={challangeTitleErrorText}
              />
              <label
                style={{
                  marginLeft: '2px',
                  marginRight: '5px',
                  font: 'normal normal bold 6px/8px Montserrat',
                  color: challengeTitleLimit < 0 ? 'red' : '#707070',
                  fontSize: '7px',
                }}>
                {challengeTitleLimit < 0 ? ` ${Math.abs(challengeTitleLimit)} Characters Exceeded ` : `Character limit: ${challengeTitleLimit}`}
              </label>
            </div>
          </FormControl>

          {/* language */}
          <FormControl className={classes.formControlWrapper}>
            <Label labelText="Language" required={true} p="0 0 0.8rem 0" />
            <SimpleFormSelect
              width={classes.full_Width}
              autoClose={true}
              options={languages}
              text={languages.filter((v) => v.id === microSkillState.language)[0].display_text}
              onSelect={(values) => { setMicroSkillState({ ...microSkillState, language: values }); }}
            />
            {/* {languageErrorText && (<label style={{ marginLeft: '5px', font: 'normal normal bold 10px Montserrat', color: '#f63b34' }}>{languageErrorText} </label>)} */}
          </FormControl>

          {/* objective */}
          <FormControl className={classes.formControlWrapper}>
            <Label labelText="Objective" required={true} p="0 0 0.8rem 0" />
            <SimpleFormSelect
              width={classes.full_Width}
              autoClose={true}
              options={objectives}
              text={objectives.filter((v) => v.id === microSkillState.objective)[0]?.display_text ?? 'Select Objective'}
              onSelect={handleObjectiveChange}
            />
          </FormControl>
        </div>

        <div className={classes.rightContainer}>
          <div className={`${classes.rtfContainer}`}>
            <Label labelText="Scenario" required={true} p="0 0 0.8rem 0" />
            <Editor
              quillRef={quillRef}
              handleChange={handleScenarioChange}
              value={microSkillState.formatted}
            />
          </div>

          <div className={classes.switchContainer}>
            <Label labelText="Convert to audio" />
            <ToggleSwitch
              checked={microSkillState.convertToAudio}
              onChange={() => setMicroSkillState({ ...microSkillState, convertToAudio: !microSkillState.convertToAudio })}
              name="convertToAudio"
            />
          </div>
        </div>

      </FlexRowDiv >

      <FormControl className={classes.action_btn_container}>
        <FlatClearButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
            marginRight: '2rem'
          }}
          onClick={() => {
            // saveDraft();

            window.history.back()
          }}>
          Back
        </FlatClearButton>
        <FlatPrimaryButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
          }}
          onClick={nextAction}>
          Next
        </FlatPrimaryButton>
      </FormControl>
    </>
  );
});
