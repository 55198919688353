export const languages = [
    {
        id: 'en-US',
        display_text: 'English',
    },
    {
        id: 'hi-IN',
        display_text: 'Hindi',
    },
    {
        id: 'bn-IN',
        display_text: 'Bengali',
    },
    {
        id: 'ta-IN',
        display_text: 'Tamil',
    },
    {
        id: 'te-IN',
        display_text: 'Telugu',
    },
    {
        id: 'gu-IN',
        display_text: 'Gujarati',
    },
    {
        id: 'kn-IN',
        display_text: 'Kannada',
    },
    {
        id: 'ml-IN',
        display_text: 'Malayalam',
    },
    {
        id: 'mr-IN',
        display_text: 'Marathi',
    },
];

export const teamEvaluationScenario = `You are the branch manager at Aditya Birla Sun Life Insurance's Branch office in Pune. Last month one of your team member was not able to recruit 10 new advisors resulting in your overall branch KPI scores to fall below the 80% threshold.
It's the last quarter and the team member has to make his numbers given the last quarter accounts for 40% of overall revenue for the branch. However, your team member appears disengaged but has not mentioned anything to you. How will you turn him around while still giving him the necessary feedback he needs to know?
`

export const teamEvaluationScenarioFormatted = `<p>You are the branch manager at <strong>Aditya Birla Sun Life Insurance's</strong> <strong>Branch office in Pune</strong>. </p><p><br></p><p>Last month one of your team member was not able to <strong><u>recruit 10 new advisors</u></strong> resulting in your overall branch KPI scores to fall below the 80% threshold.</p><p><br></p><p>It's the last quarter and the team member has to make his numbers given the <u>last quarter accounts for 40% of overall revenue</u> for the branch. </p><p><br></p><p>However, your team member appears disengaged but has not mentioned anything to you. </p><p><br></p><p>How will you turn him around while still giving him the necessary feedback he needs to know?</p>`

export const productScenario = `A customer is interested to know more about Aditya Birla Sun Life's insurance product. Your goal is to explain the details of the product & get the customer interested in asking for more details.`

export const objectives = [
    {
        id: 'performance evaluation of a team member',
        display_text: 'Performance evaluation of a team member',
    },
    {
        id: 'set up a meeting',
        display_text: 'Set up a meeting',
    },
    {
        id: 'gain customer interest',
        display_text: 'Gain customer interest',
    },
    {
        id: 'convince customer on objections',
        display_text: 'Convince customer on objections',
    },
    {
        id: 'close a sale',
        display_text: 'Close a sale',
    },
    {
        id: 'address complaint',
        display_text: 'Address complaint',
    },

];

export const genders = [
    {
        id: 'male',
        display_text: 'Male',
    },
    {
        id: 'female',
        display_text: 'Female',
    },

];
export const feedbackTypes = [
    {
        id: 'Qualitative',
        display_text: 'Qualitative',
    }

];

export const industries = [
    {
        id: 'life insurance',
        display_text: 'Life insurance',
    },
    {
        id: 'health insurance',
        display_text: 'Health insurance',
    },
    {
        id: 'pharma',
        display_text: 'Pharma',
    },
    {
        id: 'FMCG',
        display_text: 'FMCG',
    },
    {
        id: 'banking',
        display_text: 'Banking',
    },
    {
        id: 'automobile',
        display_text: 'Automobile',
    },

];
export const endUserRoles = [
    {
        id: 'manager',
        display_text: 'Manager',
    },
    {
        id: 'life insurance agent',
        display_text: 'Life insurance agent',
    },
    {
        id: 'health insurance agent',
        display_text: 'Health insurance agent',
    },
    {
        id: 'pharma sales executive',
        display_text: 'Pharma sales executive',
    },
    {
        id: 'FMCG sales representative',
        display_text: 'FMCG sales representative',
    },
    {
        id: 'bank relationship manager',
        display_text: 'Bank relationship manager',
    },
    {
        id: 'dealer sales representative',
        display_text: 'Dealer sales representative',
    },

];

export const personalities = [
    {
        id: 'working professional',
        display_text: 'Working professional',
    },
    {
        id: 'middle class salaried person',
        display_text: 'Middle class salaried person',
    },
    {
        id: 'business owner',
        display_text: 'Business owner',
    },
    {
        id: 'elderly person',
        display_text: 'Elderly person',
    },
    {
        id: 'team member',
        display_text: 'Team member',
    },
    {
        id: 'frontline sales executive',
        display_text: 'Frontline Sales Executive',
    },

];
export const temperaments = [
    {
        id: 'impatient',
        display_text: 'Impatient',
    },
    {
        id: 'calm',
        display_text: 'Calm',
    },
    {
        id: 'talkative',
        display_text: 'Talkative',
    },
    {
        id: 'detail oriented',
        display_text: 'Detail oriented',
    },
    {
        id: 'risk averse',
        display_text: 'Risk averse',
    },
    {
        id: 'confused',
        display_text: 'Confused',
    },
    {
        id: 'disengaged',
        display_text: 'Disengaged',
    },

];

export const defaultPrompt = [
    {
        id: 0,
        label: 'Persona',
        content: `You are a 35-year-old working professional from Mumbai, India named Divya.\n You've heard about the Aditya Birla Akshaya Plan but have some questions.\n You are impatient but do not want to reveal more details about yourself unless the insurance agent asks you the right questions about you.`,
        disable: false
    },
    {
        id: 1,
        label: 'Conversation Flow',
        content: `Topics to cover should include:\nDetails of Akshaya Plan\nWhy is this plan a suitable option for you\nComparison with competitor plans\nAnswering any objections you may have`,
        disable: false
    },
    {
        id: 2,
        label: 'Boundaries and conclusion',
        content: `Do not act as a life insurance agent or provide advice under any circumstances.\nIf you don't understand what the life insurance agent said, ask them to repeat it instead of responding randomly.\nIf your both speak at the same time, please say this sentence "Please go ahead, finish your point"`,
        disable: false
    },
    {
        id: 3,
        label: 'Conditional Rules',
        content: `During the discussion, if a life insurance agent says or asks anything outside of the context of this discussion such as "What do you think about the weather" or "What do you think about the recent elections?", either say "I'm not interested in discussing that" or "Can you please get back to the main point of the discussion?"`,
        disable: false
    },
]

export const defaultPromptForPerformanceEvaluation = [
    {
        id: 0,
        label: 'Persona',
        content: `You are a 25-year-old frontline sales executive from Pune, India named Samara at Aditya Birla Sun Life Insurance's agency business.You have been at Aditya Birla for the last 9 months and a key KPI for you is to recruit more life insurance agents and activate them.You have not been meeting your targets for the past couple of months. Last month you missed recruiting 10 out of the 15 new advisors. Your branch manager (named Bharat) will now have a performance evaluation discussion with you where you are expected to justify your poor performance but also understand what could you do better. You currently feel demotivated and helpless because you do not often get the support you need on time. It's important to explain this to the branch manager if he asks.`,
        disable: false
    },
    {
        id: 1,
        label: 'Conversation Flow',
        content: `Topics to cover should include:\nLast quarter's performance & it’s impact\nWhy was the performance not up to expectations\nWhat needs to improve for the frontline sales rep (Samara)\nWhat support is expected from the company and more importantly from the Branch Manager (Bharat)\nWay forward for the last quarter of the year and how will the frontline sales rep Samara achieve his targets`,
        disable: false
    },
    {
        id: 2,
        label: 'Boundaries and conclusion',
        // content: `Do not act as a manager or provide advice under any circumstances.\nIf you don't understand what the manager said, ask them to repeat it instead of responding randomly.\nIf your both speak at the same time, please say this sentence "Please go ahead, finish your point"`,
        content: `Do not play any other role other than the frontline sales executive under any circumstances. If you do not understand what the manager said, ask him or her to repeat it instead of responding with a random answer. If your both speak at the same time, please say this sentence "Please go ahead, finish your point"`,
        disable: false
    },
    {
        id: 3,
        label: 'Conditional Rules',
        // content: `During the discussion, if manager says or asks anything outside of the context of this discussion such as "What do you think about the weather" or "What do you think about the recent elections?", either say "I'm not interested in discussing that" or "Can you please get back to the main point of the discussion?"`,
        content: `During the discussion, if the manager says or asks anything outside of the context of this discussion such as "What do you think about Person A?” or asks for your thoughts about any specific person, give a safe response such as “I don’t have much information or opinion about that”`,
        disable: false
    },
]