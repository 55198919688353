import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({

   card: {
      border: '1px solid rgb(0,0,0,0.2)',
      // backgroundColor: "rgba(0,0,0,0.2) !important",//commented while img was hiding
      // boxShadow: `inset 0 -160px 45px -46px rgba(0, 0, 0, 0.87)`,//commented while img was hiding
      // '-webkit-box-shadow': `inset 0 -160px 45px -46px rgba(0, 0, 0, 0.9)`,//commented while img was hiding
     boxShadow: `0px 0px 0px 0px #fff`,
      transition: 'box-shadow 0.5s',
      '&:hover': {
         boxShadow: `0px 0px 10px 1px rgba(0, 0, 0, 0.6)`
      },
      position: 'relative',
      '& > img': {
         borderRadius: '10px',
         width: '100%',
         height: '100%',
         maxWidth: '100%',
         maxHeight: '100%',
         'object-fit': 'cover',
         aspectRatio: 0.80,
         position: 'absolute',
         zIndex: 0

      }
   },
   lottieBox: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      borderRadius: '10px',
      zIndex: 9999,
      backgroundColor: 'rgb(0,0,0,0.4)',
      '& > div': {
         height: '65%'
      },
      '& > h5': {
         color: "#ffffff",
         fontFamily: "Montserrat",
         fontSize: "1.5cqi",
         letterSpacing: "0.1cqi",
         fontWeight: 700,
         textAlign: 'center'
      }
   },
   cardRow1: {
      zIndex: 100,//added while img was hiding
      backgroundColor: "rgba(0,0,0,0.2) !important",//added while img was hiding
      position: 'absolute',
      height: '13%',
      width: '100%',
      display: 'flex',
      '& > span:first-child': {
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         // backgroundColor: 'rgb(250 237 237 / 5%)',
         backgroundColor: 'rgb(112,112,112,0.5)',
         border: '2px solid #ffffff',
         borderRadius: '4px',
         margin: '0.8cqi 1.8cqi 0 1cqi',
         letterSpacing: '0.03rem',
         height: '60%',
         width: '35%',
         color: "#ffffff",
         fontFamily: "Montserrat",
         fontSize: "0.86cqi",
         fontWeight: 700,
         transition: '0.5s',
         '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgb(255,255,255,0.2)',
            color: "#f4511e",
            borderColor: '#f4511e',
            '-webkit-box-shadow': '-10px 10px 25px -5px rgba(0,0,0,0.5)'
            // fontSize: "0.8rem",
         }
      }
   },
   btnBox: {
      // border: '1px solid blue',
      gap: '1rem',
      height: '70%',
      width: '50%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: "flex-end",
      marginTop: '0.66rem'
   },
   editBtn: {
      // position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '2.5rem',
      width: '2.5rem',
      border: 'none',
      borderRadius: '50%',
      backgroundColor: 'rgb(112,112,112,0.5)',
      '& > img': {
         transition: '0.4s',
         height: '1rem !important',
         width: '1rem !important',
         filter: 'invert(100%) sepia(95%) saturate(21%) hue-rotate(288deg) brightness(104%) contrast(106%)',
      },
      '&:hover': {
         cursor: 'pointer !important',
         '& > img': {
            scale: 1.2,
            filter: 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(328deg) brightness(99%) contrast(93%)'

         }
      }
   },

   cardRow2: {
      // border: '1px solid red',
      zIndex: 100,//added while img was hiding
      boxShadow: `inset 0 -160px 45px -46px rgba(0, 0, 0, 0.87)`,//added while img was hiding
      '-webkit-box-shadow': `inset 0 -160px 45px -46px rgba(0, 0, 0, 0.9)`,//added while img was hiding
      backgroundColor: "rgba(0,0,0,0.2) !important",//added while img was hiding
      top: '13%',
      position: 'relative',
      height: '87%',
      cursor: 'pointer',
      '& > h1': {
         // border: '1px solid green',
         position: 'absolute',
         bottom: '7cqi',
         left: '2cqi',
         color: "#FFFFFF",
         fontFamily: "Montserrat",
         fontSize: "1.4cqi",
         fontWeight: 600,
         overflowX: 'hidden',
         overflowY: 'hidden',
         maxWidth: '16cqi',
         maxHeight: '5cqi'
      }
   },
   viewsLikes: {
      // border: '1px solid red',
      position: 'absolute',
      bottom: '3.6cqi',
      left: '2.2cqi',
      display: 'flex',
      width: '10cqi',
      gap: '1.8cqi',
      '& > span': {
         display: 'flex',
         alignItems: 'center',
         color: '#fff',
         fontFamily: 'Montserrat',
         fontSize: '1cqi',
         fontWeight: 600,
         '& > img': {
            height: '1cqi',
            width: '1cqi',
            marginRight: '0.5cqi',
            filter: 'invert(100%) sepia(100%) saturate(590%) hue-rotate(180deg) brightness(100%) contrast(100%)'

         }
      }
   },
   brandIcon: {
      // border: '1px solid red',
      position: 'absolute',
      bottom: '6cqi',
      right: '1.2cqi',
      height: '3.2cqi',
      // width: '3.2cqi',
      width: 'auto',
      maxWidth: '6cqi',
      maxHeight: '3.2cqi',
   }

}))

export default useStyles;
