import React, { useState, useRef, useEffect, useContext } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';

import styles, {
  FlexRowDiv,
  Box,
  TranscriptHeading,
  TranscriptTextArea,
} from './style';
import TextInput from './components/TextInput';
import TextArea from './components/TextArea';
import FileSelect from './components/FileSelect';
import FormControl from './components/FormControl';
import Label, { SmallFontLabel, HeadingLabel } from './components/Label';
import FlatPrimaryButton from './components/FlatPrimaryButton';
import FlatClearButton from './components/FlatClearButton';
import ToggleSwitch from './components/ToggleSwitch/index';
import NumericInput from './components/NumericInput';
import CustomDropdown from './components/CustomDropdown/index';
import Loader from '../Loader';
import Editor from './Editor';
import MSDialog from './MSDialog';
import SimpleFormSelect from './components/SimpleFormSelect';

//new
import Snackbar from '@mui/material/Snackbar';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

//services
import challengesServices from '../../services/challenges';

//utils
import commonUtil from '../../utils/commonUtil';
import useRouter from '../../utils/useRouter';
import useQuery from '../../utils/useQuery';

//configs
import { keys, urls } from '../../configs';

import { GlobalStates } from '../../App';

//constants
import constants from '../../constants';

export const Page1_challange = React.memo((props) => {
  const router = useRouter();
  const query = useQuery();
  const companyId = query.get("c_id");
  const microskillId = query.get("ms_id");
  const challengeId = query.get("challenge_id");
  const challengeName = query.get("challengeName");
  const microskillName = query.get("microskillName");
  const { innerWidth: width, innerHeight: height } = window;

  const languages = [
    {
      id: 'en-US',
      display_text: 'English',
    },
    {
      id: 'hi-IN',
      display_text: 'Hindi',
    },
    {
      id: 'bn-IN',
      display_text: 'Bengali',
    },
    {
      id: 'ta-IN',
      display_text: 'Tamil',
    },
    {
      id: 'te-IN',
      display_text: 'Telugu',
    },
    {
      id: 'gu-IN',
      display_text: 'Gujarati',
    },
    {
      id: 'kn-IN',
      display_text: 'Kannada',
    },
    {
      id: 'ml-IN',
      display_text: 'Malayalam',
    },
    {
      id: 'mr-IN',
      display_text: 'Marathi',
    },
  ];
  const quillRef = useRef();
  const { setCurrentPage, pages, isEdit } = props;
  const { microSkillState, setMicroSkillState } = props;
  const classes = styles();
  const [globalStates] = useContext(GlobalStates);
  //microskill states
  const [challangeTitle, setChallangeTitle] = useState(microSkillState.Challenge_Name);
  const [challengeTitleLimit, setChallangeTitleLimit] = useState(
    80 - microSkillState.Challenge_Name.length,
  );
  const [scenario, setScenario] = useState(microSkillState.Scenar);
  console.log('scenario = ', scenario);
  const [scenarioLimit, setScenarioLimit] = useState(
    250 - microSkillState.Scenar.length,
  );
  // microSkillState.Formatted_transcript,
  const [isTranscript, setIsTranscript] = useState(microSkillState.isTranscript || false);
  const [transcript, setTranscript] = useState(microSkillState.Formatted_transcript);
  const [isVideoBased, setIsVideoBased] = useState(
    microSkillState.Input_key == 'video',
  );
  const [uploadFile, setUploadFile] = useState(microSkillState.Video_name);
  const [selectedLanguages, setSelectedLanguages] = useState(
    microSkillState.languages,
  );
  const [languageErrorText, setLanguageErrorText] = useState(null);

  function getWindowHeight() {
    const { innerHeight: height } = window;
    // console.log('getWindowHeight window = ', window);
    return height;
  }

  const [windowHeight, setWindowHeight] = useState(getWindowHeight());

  // const [ facts, setFacts ] = useState([]);
  const [listening, setListening] = useState(false);
  const [jobId, setJobId] = useState('')
  const [failedDialog, setFailedDialog] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    console.log('EventSourcePolyfill useEffect');
    if (listening) {
      console.log('EventSourcePolyfill calling!');
      const eventBaseURL = `${urls.baseUrl}${urls.apiV1}`;
      const session = JSON.parse(localStorage.getItem('session'));
      const events = new EventSourcePolyfill(`${eventBaseURL}/microskill/challenge/get-transcript/${jobId}/status`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'x-api-key': keys.apiKey,
          'x-access-token': session.accessToken,
          'x-refresh-token': session.refreshToken
        }
      });

      events.onmessage = (res) => {
        const data = JSON.parse(res.data);
        console.log('EventSourcePolyfill parsed data = ', data);
        if (data?.progress) setProgress(data.progress);
        if (data.status === "completed") {
          if (!data.returnvalue) return
          events.close();
          updateTranscript(data?.returnvalue?.transcript);
          setMicroSkillState({ ...microSkillState, extracted: data?.returnvalue?.audioFileLocation, isUploading: null, Sample_transcript: data?.returnvalue?.transcript });
          updateTranscriptProgress(TranscriptStatus.LOADED);
          setJobId('')
          setListening(false);
          setProgress(0);
        }
      }
    }
  }, [listening]);

  useEffect(() => {
    function handleResize() {
      setWindowHeight(getWindowHeight());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log('transcript = ', transcript);
    setMicroSkillState({ ...microSkillState, Formatted_transcript: transcript });
  }, [transcript]);

  useEffect(() => {
    let isEdit = query.get('edit');
    console.log('Listening while isEdit =  ', listening);
    if (isEdit) {
      setChallangeTitle(microSkillState.Challenge_Name);
      setChallangeTitleLimit(80 - microSkillState.Challenge_Name.length);
      setScenario(microSkillState.Scenar);
      setScenarioLimit(250 - microSkillState.Scenar.length);
      if (microSkillState.Formatted_transcript.length) {
        setTranscript(microSkillState.Formatted_transcript);
        updateTranscriptProgress(TranscriptStatus.LOADED);
        setUploadFile(microSkillState.Video_name);
      }
    }
  }, [microSkillState]);

  const updateSelectedLanguages = (values) => {
    if (values.length > 0) {
      setLanguageErrorText(null);
    }
    setSelectedLanguages(values);
    setMicroSkillState({ ...microSkillState, languages: values });
  };

  const changeTranscript = (title) => {
    console.log('changeTranscript title length = ', title.length);
    console.log('changeTranscript transcript length = ', transcript.length);
    if (title.length === transcript.length) return
    setTranscript(title);

  };

  const updateChallengeTitle = (title) => {
    const remainingChar = 80 - title.length;
    // if (remainingChar < 0) {
    //   return;
    // }
    setChallangeTitleLimit(remainingChar);
    setChallangeTitle(title);

    setMicroSkillState({ ...microSkillState, Challenge_Name: title });
  };
  const updateScenario = (title) => {
    const remainingChar = 250 - title.length;
    // if (remainingChar < 0) {
    //   return;
    // }
    setScenarioLimit(remainingChar);
    setScenario(title);
    setMicroSkillState({ ...microSkillState, Scenar: title });
  };
  const updateTranscript = (title) => {
    setTranscript(title);
    setMicroSkillState((prevState) => ({
      ...prevState,
      Sample_transcript: title,
    }));
  };

  const updateIsVideoBased = (item) => {
    setIsVideoBased(item);
    setMicroSkillState({
      ...microSkillState,
      Input_key: item == true ? 'video' : 'audio',
    });
  };

  const updateUploadFile = (name) => {
    setUploadFile(name);
    // let newMicroSkillState = { ...microSkillState, Video_name: name };
    // setMicroSkillState(newMicroSkillState);
  };

  //microskill states end
  const [challangeTitleErrorText, setChallangeTitleErrorText] = useState(null);
  const [scenarioErrorText, setScenarioErrorText] = useState(null);
  const [confirmContinueDialog, setConfirmContinueDialog] = useState(false);

  const TranscriptStatus = {
    EMPTY: 'Upload Best Practice Sample Pitch To Generate Transcript',
    UPLOADING: 'Uploading file',
    GENERATING: 'Generating Transcript',
    LOADED: 'Sample Pitch Transcript:',
  };
  const [transcriptProgress, setTranscriptProgress] = useState(
    microSkillState.Sample_transcript.length == 0
      ? TranscriptStatus.EMPTY
      : TranscriptStatus.LOADED,
  );
  const [forward_xls_toast, set_forward_xls_toast] = useState(false);
  const [forward_xls_toast_msg, set_forward_xls_toast_msg] = useState('');
  const updateTranscriptProgress = (status) => {
    setTranscriptProgress(status);
  };

  const nextAction = () => {
    console.log('microSkillState page 1 = ', microSkillState);

    if (challangeTitle.length == 0) {
      setChallangeTitleErrorText('Challenge Title cannot be Empty.');
      return;
    }
    if (scenario.length == 0) {
      setScenarioErrorText('Scenario cannot be Empty.');
      return;
    }

    if (selectedLanguages.length == 0) {
      setLanguageErrorText('Please select a language.');
      return;
    }
    if (transcriptProgress == TranscriptStatus.GENERATING || transcriptProgress == TranscriptStatus.UPLOADING) {
      set_forward_xls_toast(true);
      set_forward_xls_toast_msg('Uploading in progress. Please wait...');
      return;
    }
    if (
      transcriptProgress == TranscriptStatus.EMPTY &&
      transcript.length == 0
    ) {
      // set_forward_xls_toast(true);
      // set_forward_xls_toast_msg('Transcript text can not be empty.');
      setConfirmContinueDialog(true);
      return;
    }
    let currentTranscriptValue = setCurrentTranscriptValue();
    if (currentTranscriptValue.length == 0) {
      set_forward_xls_toast(true);
      set_forward_xls_toast_msg('Transcript text can not be empty.');
      return;
    }

    console.log('microSkillState page 1 quillRef = ', quillRef.current.value);
    // setMicroSkillState({ ...microSkillState, Formatted_transcript: quillRef.current.value });
    goToNextPage();
  };
  const [shouldsSaveDraft, setShouldSaveDraft] = useState(false);
  const saveDraft = () => {
    setCurrentTranscriptValue();
    setShouldSaveDraft(true);
  };

  useEffect(() => {
    if (shouldsSaveDraft) {
      // setShouldSaveDraft(false);
      // addChallengeAction();
    }
  }, [microSkillState]);

  const setCurrentTranscriptValue = () => {
    let currentTranscriptValue = transcript;
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      currentTranscriptValue = editor.getText().trim();
    }
    setTranscript(currentTranscriptValue);
    setMicroSkillState({
      ...microSkillState,
      Sample_transcript: currentTranscriptValue,
    });
    return currentTranscriptValue;
  };

  const continueAction = () => {
    setTranscriptProgress(TranscriptStatus.LOADED);
    set_forward_xls_toast(true);
    set_forward_xls_toast_msg('Transcript text can not be empty.');
  };
  const goToNextPage = () => {
    setCurrentPage(pages[1]);
  };



  const onFileSelect = async (file) => {
    // console.log('file = ', file);
    if (file && file[0].type === "audio/mpeg") {
      globalStates.handleToast(true, "Invalid file format", "error")
      return
    }
    updateTranscriptProgress(TranscriptStatus.UPLOADING);

    let json = {
      companyId: companyId,
      microskillId: microskillId,
      challengeId: challengeId,
      transcriptMediafile: file[0],
      language: microSkillState.languages
    }
    let payload = await commonUtil.jsonToFormData(json);
    updateUploadFile(file[0].name);
    try {
      const res = await challengesServices.uploadTranscriptMediaFile(payload);
      if (res.status === 200 || res.status === 201) {
        let jobId = res.data.data.jobId;
        setMicroSkillState({
          ...microSkillState,
          Media_Path: res.data.data.filePath,
          isUploading: true,
          oldSampleTranscript: microSkillState.Sample_transcript,
          oldFormattedTranscript: microSkillState.Formatted_transcript,
          Sample_transcript: '',
          Formatted_transcript: '',
          Video_name: file[0].name
        });
        updateTranscriptProgress(TranscriptStatus.GENERATING);
        setJobId(jobId);
        setListening(true);
      } else {
        globalStates.handleToast(true, "Failed to upload file, please try again", "error")
        updateTranscriptProgress(TranscriptStatus.EMPTY);
      }


    } catch (error) {
      console.error(error);
      updateTranscriptProgress(TranscriptStatus.EMPTY);
    }
  };

  const handleDisableTranscript = () => {
    setIsTranscript(!isTranscript);
    setMicroSkillState({ ...microSkillState, isTranscript: !isTranscript });
  }


  const TranscriptContainer = () => {
    return (
      <Box
        height={windowHeight}
        className={`${transcriptProgress == TranscriptStatus.LOADED
          ? classes.withoutBorderBox
          : classes.box
          }`}>
        {(transcriptProgress == TranscriptStatus.GENERATING || transcriptProgress == TranscriptStatus.UPLOADING) && (
          <Loader fill="#f4511e" />
        )}
        {transcriptProgress != TranscriptStatus.LOADED && (
          <TranscriptHeading>
            {transcriptProgress}
            {transcriptProgress == TranscriptStatus.GENERATING ? ` - ${progress}%` : null}
          </TranscriptHeading>
        )}
        {transcriptProgress == TranscriptStatus.LOADED && (
          <Editor
            quillRef={quillRef}
            handleChange={changeTranscript}
            value={transcript}
            style={{ width: '100%', height: '90%' }}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      {/* <div className={classes.heading_container}>
        <HeadingLabel>Create Roleplay Challenge</HeadingLabel>
      </div> */}
      <FlexRowDiv className={classes.challangeContainer}>
        <div className={classes.leftContainer}>
          <div className={classes.leftTopContainer}>
            {/* language */}
            <FormControl
              className={classes.full_Width}
              style={{ textAlign: 'left' }}>
              <Label>Languages:</Label>
              <SimpleFormSelect
                // style={{ width: '100%' }}
                width={classes.full_Width}
                autoClose={true}
                options={languages}
                text={
                  languages.filter((v) => v.id === selectedLanguages)[0]
                    .display_text
                }
                onSelect={(values) => {
                  updateSelectedLanguages(values);
                }}
              />
              {languageErrorText && (
                <label
                  style={{
                    marginLeft: '5px',
                    font: 'normal normal bold 10px Montserrat',
                    color: '#f63b34',
                  }}>
                  {languageErrorText}
                </label>
              )}
            </FormControl>
            {/* challange title */}
            <FormControl
              className={classes.full_Width}
              style={{ textAlign: 'left' }}>
              <Label>Challenge Title</Label>
              <div
                style={{
                  border: challengeTitleLimit < 0 ? 'solid 0.5px #f4511e' : 'solid 0.5px #dde0e2',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}>
                <TextInput
                  placeholder={'Enter Challenge Title Here'}
                  style={{ width: '70%' }}
                  border={'transparent'}
                  width={classes.full_Width}
                  value={challangeTitle}
                  onChangeText={(t) => {
                    setChallangeTitleErrorText(null);
                    updateChallengeTitle(t);
                  }}
                // errorText={challangeTitleErrorText}
                />
                <label
                  style={{
                    marginLeft: '2px',
                    marginRight: '5px',
                    font: 'normal normal bold 6px/8px Montserrat',
                    color: challengeTitleLimit < 0 ? '#f4511e' : '#707070',
                    fontSize: '7px',
                  }}>
                  {challengeTitleLimit < 0 ? ` ${Math.abs(challengeTitleLimit)} Characters Exceeded ` : `Character limit: ${challengeTitleLimit}`}
                </label>
              </div>
              {challangeTitleErrorText && (
                <label
                  style={{
                    marginLeft: '5px',
                    font: 'normal normal bold 10px Montserrat',
                    color: '#f63b34',
                  }}>
                  {challangeTitleErrorText}
                </label>
              )}
              {challengeTitleLimit < 0 && (
                <label
                  style={{
                    marginLeft: '5px',
                    font: 'normal normal bold 10px Montserrat',
                    color: '#f63b34',
                  }}>
                  Character limit exceeded
                </label>
              )}
            </FormControl>
            {/* scenario */}
            <FormControl
              className={classes.full_Width}
              style={{ textAlign: 'left' }}>
              <Label>Scenario</Label>
              <div
                style={{
                  border: scenarioLimit < 0 ? 'solid 0.5px #f4511e' : 'solid 0.5px #dde0e2',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  width: '100%',
                }}>
                <TextArea
                  placeholder={'Enter Scenario Description Here'}
                  resize={'none'}
                  border={'transparent'}
                  width={'100%'}
                  text={scenario}
                  onChangeText={(text) => {
                    setScenarioErrorText(null);
                    updateScenario(text);
                  }}></TextArea>
                <label
                  style={{
                    marginRight: 10,
                    marginBottom: 5,
                    font: 'normal normal bold 6px/8px Montserrat',
                    color: scenarioLimit < 0 ? '#f4511e' : '#707070',
                    fontSize: '7px',
                  }}>
                  {scenarioLimit < 0 ? ` ${Math.abs(scenarioLimit)} Characters Exceeded` : `Character limit: ${scenarioLimit}`}
                </label>
              </div>
              {scenarioErrorText && (
                <label
                  style={{
                    marginLeft: '5px',
                    font: 'normal normal bold 10px Montserrat',
                    color: '#f63b34',
                  }}>
                  {scenarioErrorText}
                </label>
              )}
              {scenarioLimit < 0 && (
                <label
                  style={{
                    marginLeft: '5px',
                    font: 'normal normal bold 10px Montserrat',
                    color: '#f63b34',
                  }}>
                  Character limit exceeded
                </label>
              )}
            </FormControl>

            {/* sample pitch */}
            <FormControl className={classes.full_Width}>
              <Label>Best Practices Sample Pitch:</Label>
              <FileSelect
                width={'100%'}
                accept={constants.SPOTLIGHT_SUPPORTED_EXTENSIONS}
                filename={uploadFile}
                disabled={transcriptProgress == TranscriptStatus.GENERATING || transcriptProgress == TranscriptStatus.UPLOADING}
                onFileSelect={onFileSelect}
              />
              <span className={classes.info}>Supported files: {constants.SPOTLIGHT_SUPPORTED_EXTENSIONS.join(', ').replace(/\./g, '').toUpperCase()}</span>

              <div className={classes.smTranscriptContainer}>
                {TranscriptContainer()}
              </div>
            </FormControl>

            {/* disable transcript */}
            <FormControl className={classes.learnerAssistContainer} style={{ width: '55%' }}>
              <div className={classes.switch_container} style={{ maxWidth: '225px' }}>
                <Label style={{ paddingBottom: 0, minWidth: 'fit-content' }}>Show transcript to learner:</Label>
                <ToggleSwitch
                  checked={isTranscript}
                  onChange={handleDisableTranscript}
                  name="learnerAssist"
                />
              </div>
            </FormControl>

            {/* learner input */}
            <FormControl style={{ display: 'none' }}>
              <Label>Learner Input:</Label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}>
                <SmallFontLabel
                  style={{
                    marginLeft: '10px',
                    opacity: '0.75',
                    color: '#484848',
                    fontWeight: '300',
                    fontSize: '0.60rem',
                  }}>
                  Audio-based
                </SmallFontLabel>
                <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                  <ToggleSwitch
                    bidirectional={'true'}
                    checked={isVideoBased}
                    onChange={(e) => {
                      updateIsVideoBased(!isVideoBased);
                    }}
                  />
                </div>
                <SmallFontLabel
                  style={{
                    marginLeft: '10px',
                    opacity: '0.75',
                    color: '#484848',
                    fontWeight: '300',
                    fontSize: '0.60rem',
                  }}>
                  Video-based
                </SmallFontLabel>
              </div>
            </FormControl>

          </div>
        </div>
        <div className={classes.rightContainer}>{TranscriptContainer()}</div>
      </FlexRowDiv>
      <FormControl className={classes.action_btn_container}>
        <FlatPrimaryButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
          }}
          disabled={challengeTitleLimit < 0 || scenarioLimit < 0}
          // disabled={transcriptProgress == TranscriptStatus.GENERATING}
          onClick={() => {
            nextAction();
          }}>
          Next
        </FlatPrimaryButton>
        {/* <div className={classes.save_btn_container}>
          <FlatClearButton
            style={{ padding: '15px 30px', minWidth: '20%' }}
            onClick={() => {
              saveDraft();
            }}>
            Save Draft
          </FlatClearButton>
        </div> */}
      </FormControl>
      <MSDialog
        message={
          "You haven't uploaded a sample video and generated a transcript, do you still wish to continue?"
        }
        open={confirmContinueDialog}
        setOpen={setConfirmContinueDialog}
        leftButtonTitle={'UPLOAD NOW'}
        rightButtonTitle={'CONTINUE ANYWAY'}
        leftButtonAction={() => setConfirmContinueDialog(false)}
        rightButtonAction={() => {
          setConfirmContinueDialog(false);
          continueAction();
        }}
      />
      <MSDialog
        message={"Failed to generate transcript."}
        open={failedDialog}
        setOpen={setFailedDialog}
        leftButtonTitle={'Re-Try'}
        rightButtonTitle={'CONTINUE ANYWAY'}
        leftButtonAction={() => setListening(true)}
        rightButtonAction={() => {
          setFailedDialog(false);
          continueAction();
        }}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={forward_xls_toast}
        autoHideDuration={6000}
        onClose={() => {
          set_forward_xls_toast(false);
          set_forward_xls_toast_msg('');
        }}
        message={<span id="message-id">{forward_xls_toast_msg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              set_forward_xls_toast(false);
              set_forward_xls_toast_msg('');
            }}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
});
