import React from 'react'

//mui
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  label: {
    // padding: '1rem 0rem',
    color: "rgb(0, 0, 0, 0.9)",
    fontFamily: "Montserrat",
    // fontSize: "0.8rem",
    // fontWeight: "700",
  },
}));

const Label = ({ display = null, labelText, fs = '0.8rem', fw = 700, color = "#484848", htmlFor = '', p = 0, required = false }) => {
  const classes = useStyles();
  const customStyles = {
    fontSize: fs,
    fontWeight: fw,
    color: color,
    cursor: htmlFor.length && 'pointer',
    padding: p
  }
  if (p) customStyles.display = 'block';

  return (
    <label htmlFor={htmlFor} className={classes.label} style={customStyles}>{labelText}{required ? <span style={{ color: 'red' }}>*</span> : null}</label>
  )
}

export default Label;


