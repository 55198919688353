import React, { createContext, useEffect, useState } from 'react';
import { Route, Router } from 'react-router';
import { createBrowserHistory } from "history";
import dotenv from 'dotenv'

// mui
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//components
import SnackbarContainer from './components/snackbar';
import LoadingDialog from './components/progress-dialog';

// views
import Layout from './components/layout';
import LoginPage from './views/login';
import CompaniesPage from './views/companies';
import ProjectsPage from './views/projects';
import CreateMicroskillsPage from './views/create-microskill';
import UserLaunchPage from './views/user-launch';
import ManageMicroskillsPage from './views/manage-microskills';
import ManageBulletinPage from './views/manage-bulletin'
import GbaPage from './views/challenges/game-based-assessment';
import LearningAidPage from './views/challenges/learning-aid';
import ScormPage from './views/challenges/scorm';
import SpotlightPage from './views/challenges/spotlight';
import TwoWaySpotlightPage from './views/challenges/two-way-spotlight';
import DocumentsPage from './views/documents';
import DriveScreen from './views/drive';
import AddUserPage from './views/add-user';
import ComingSoon from './components/coming-soon';
import ManageUserPage from './views/manage-user/index.js';
import NewForms from './views/forms';
import BulletinPage from './views/bulletin'
import FormsQuestionsPage from './views/forms-questions';
import GroupsPage from './views/groups';
import CopyMicroskillPage from './views/copy-microskill';
import UserDetailsPage from './views/user-details';
import AnalyticsPage from './views/analytics';
import PushNotificationsPage from './views/push-notifications';
import QrBasedChallengePage from './views/challenges/qr-based-challenge';
import BaseRoute from './views/base-route.js';

//style
// import theme from "./theme";
import './App.css';

import constants from './constants';
import FormBasedChallengePage from './views/challenges/form-based-challenge/index.js';

const history = createBrowserHistory();
export const GlobalStates = createContext();

const App = () => {
  dotenv.config()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  // const defaultToastPosition = { vertical: 'bottom', horizontal: 'left' };
  const defaultToastPosition = { vertical: 'top', horizontal: 'center' };
  const [sideMenu, setSideMenu] = useState({ isOpen: false, defaultWidth: constants.DRAWER_WIDTH });
  const [progressDialogText, setProgressDialogText] = useState('Please wait...');
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState('');
  const [toastPosition, setToastPosition] = useState(defaultToastPosition);
  const [toastAutoHideDuration, setToastAutoHideDuration] = useState(6000);

  const handleToast = (open, message, severity, position = defaultToastPosition, autoHideDuration = 6000) => {
    setIsToast(open);
    setToastMessage(message);
    setToastSeverity(severity);
    setToastPosition(position);
    setToastAutoHideDuration(autoHideDuration);
  }

  const handleProgressDialog = (open, dialogText = 'Please wait...') => {
    setProgressDialogOpen(open);
    setProgressDialogText(dialogText);
  }

  const [globalStates, setGlobalStates] = useState({ handleToast, handleProgressDialog, appBarTitle: 'Master-O Studio', showMsName: false });
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <GlobalStates.Provider value={[globalStates, setGlobalStates, sideMenu]}>
            <Route exact path='/'>
              <Layout
                Component={LoginPage}
                withHeader={false}
                withDrawer={false}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>

            <Route exact path='/login'>
              <Layout
                Component={LoginPage}
                withHeader={false}
                withDrawer={false}
                isProtected={false}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>

            <Route exact path='/companies'>
              <Layout
                Component={CompaniesPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/projects/:companyId'>
              <Layout
                Component={ProjectsPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/groups'>
              <Layout
                Component={GroupsPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/microskill-studio'>
              <Layout
                Component={CreateMicroskillsPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/user-launch'>
              <Layout
                Component={UserLaunchPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/push-notifications'>
              <Layout
                Component={PushNotificationsPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route path="/add-forms">
              <Layout
                Component={NewForms}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/manage-bulletin'>
              <Layout
                Component={ManageBulletinPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/bulletin'>
              <Layout
                Component={BulletinPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route path="/forms">
              <Layout
                Component={FormsQuestionsPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path="/add-user">
              <Layout
                Component={AddUserPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/manage-microskills/:companyId/:projectId'>
              <Layout
                Component={ManageMicroskillsPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/copy-microskill'>
              <Layout
                Component={CopyMicroskillPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/microskill-studio/game-based-assessment'>
              <Layout
                Component={GbaPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/microskill-studio/learning-aid'>
              <Layout
                Component={LearningAidPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/microskill-studio/scorm'>
              <Layout
                Component={ScormPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/documents/:companyId'>
              <Layout
                Component={DocumentsPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/microskill-studio/spotlight'>
              <Layout
                Component={SpotlightPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/microskill-studio/two-way-spotlight'>
              <Layout
                Component={TwoWaySpotlightPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/microskill-studio/form-based-challenge'>
              <Layout
                Component={FormBasedChallengePage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route path='/drive'>
              <Layout
                Component={DriveScreen}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route path='/coming-soon'>
              <Layout
                Component={ComingSoon}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route path='/manage-user'>
              <Layout
                Component={ManageUserPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/user-details'>
              <Layout
                Component={UserDetailsPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/analytics'>
              <Layout
                Component={AnalyticsPage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
            <Route exact path='/microskill-studio/qr-based-challenge'>
              <Layout
                Component={QrBasedChallengePage}
                withHeader={true}
                withDrawer={true}
                isProtected={true}
                isMobile={isMobile}
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
              />
            </Route>
          </GlobalStates.Provider>
        </Router>
      </ThemeProvider>
      <SnackbarContainer
        open={isToast}
        setOpen={setIsToast}
        message={toastMessage}
        severity={toastSeverity}
        autoHideDuration={toastAutoHideDuration}
        vertical={toastPosition.vertical}
        horizontal={toastPosition.horizontal}
      />
      {progressDialogOpen ? <LoadingDialog
        progressDialogOpen={progressDialogOpen}
        setProgressDialogOpen={setProgressDialogOpen}
        dialogText={progressDialogText}
      /> : null}
    </div>
  );
}
export default App;