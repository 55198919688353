import React, { useState, useContext, useEffect } from 'react';
import styles, { FlexRowDiv } from './style';
import Switch from 'react-ios-switch';

//new
import { makeStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

//components
import { HeadingLabel, SmallFontLabel } from './components/Label';
import FlatPrimaryButton from './components/FlatPrimaryButton';
import FlatClearButton from './components/FlatClearButton';
import SimpleFormSelect from './components/SimpleFormSelect';
import NumericInput from './components/NumericInput';
import ToggleSwitch from './components/ToggleSwitch';
import FormControl from './components/FormControl';
import Label from './components/Label';

//constants
import constants from '../../constants';

//mui
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';



//utils
import useQuery from '../../utils/useQuery';
import useRouter from '../../utils/useRouter';
import isEmpty from '../../utils/isEmpty';

//contexts
import { GlobalStates } from '../../App';

//services
import challengesServices from '../../services/challenges';
import textToSpeechServices from '../../services/text-to-speech';
import commonUtil from '../../utils/commonUtil';


const circleCheck = require('../../assets/circleCheck.png');
const Page4_CompletionCriteria = (props) => {
  const query = useQuery();
  let isEdit = query.get('edit');
  const challengeId = query.get("challenge_id");
  const companyId = query.get("c_id");
  const projectId = query.get("p_id");
  const microskillId = query.get("ms_id");
  const router = useRouter();
  const [globalStates] = useContext(GlobalStates);

  const { microSkillState, setMicroSkillState, pages, setCurrentPage } = props;
  const [savingInProgress, setsavingInProgress] = useState(false);

  const type = {
    OPTIONS: 'options',
    SWITCH: 'switch',
    BIDIRECTIONALSWITCH: 'bidirection_switch',
    NUMBERINPUT: 'numeric_input',
    WEIGHTAGEINPUT: 'weightage_input',
    LABEL: 'label',
  };

  const rangeOptions = [
    {
      id: 1,
      display_text: 'Minimum',
    },
    {
      id: 2,
      display_text: 'Maximum',
    },
    {
      id: 3,
      display_text: 'Range',
    },
  ];

  const toneTypeOptions = [
    {
      id: 1,
      display_text: 'Minimum',
    },
    {
      id: 2,
      display_text: 'Negative',
    },
    {
      id: 3,
      display_text: 'Neutral',
    },
  ];

  const clarityTypeOptions = [
    {
      id: 1,
      display_text: 'Neutral',
    },
  ];

  const tableItems = [
    {
      id: 1,
      fieldName: 'Duration',
      isEnabled: () => getEnableDuration(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Time Limit',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => getSelectedDuration(),
          onSelect: (item) => handleDurationSelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          // lowerValue: () => getLowerDuration(),
          lowerValue: () => lowerDuration,
          onLowerSelect: (item) => updateLowerDuration(item),
          value: () => getDuration(),
          onSelect: (item) => updateDuration(item),
          selectedOption: () => getSelectedDuration(),
          unit: 'Seconds',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('Duration'),
          onSelect: (item) => updateWeightageFor('Duration', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableDuration(),
          onSelect: (item) => {
            // handleDurationSwitchChange(item);
          },
        },
      ],
    },
    {
      id: 2,
      fieldName: 'Speech Rate',
      isEnabled: () => getEnableSpeechRate(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Speech Rate',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => selectedSpeechRate,
          onSelect: (item) => handleSpeechRateSelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          lowerValue: () => lowerSpeechRate,
          onLowerSelect: (item) => updateLowerSpeechRate(item),
          value: () => speechRate,
          onSelect: (item) => updateSpeechRate(item),
          selectedOption: () => selectedSpeechRate,
          unit: 'Words Per Minute',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('SpeechRate'),
          onSelect: (item) => updateWeightageFor('SpeechRate', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableSpeechRate(),
          onSelect: (item) => {
            handleSpeechRateSwitchChange(item);
          },
        },
      ],
    },
    {
      id: 3,
      fieldName: 'Keywords Match',
      isEnabled: () => getEnableKeywordMatch(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Keywords Match',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => getSelectedKeywordMatch(),
          onSelect: (item) => handleKeywordMatchSelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          isEditable: false,
          lowerValue: () => getLowerKeywordMatchPercent(),
          onLowerSelect: (item) => updateLowerKeywordMatch(item),
          value: () => getKeywordMatchPercent(),
          onSelect: (item) => updateKeywordMatch(item),
          selectedOption: () => getSelectedKeywordMatch(),
          unit: 'Minimum Percent',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('KeyMatch'),
          onSelect: (item) => updateWeightageFor('KeyMatch', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableKeywordMatch(),
          onSelect: (item) => {
            handleKeywordMatchSwitchChange(item);
          },
        },
      ],
    },
    {
      id: 7,
      // fieldName: 'Section-wise analytics',
      fieldName: microSkillState.objective === 'performance evaluation of a team member' ? 'Performance feedback' : 'Product feedback',
      isEnabled: () => getEnableTranscript(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: microSkillState.objective === 'performance evaluation of a team member' ? 'Performance feedback' : 'Product feedback',
        },
        // {
        //   id: 2,
        //   type: type.OPTIONS,
        //   options: rangeOptions,
        //   selected: () => getSelectedTranscript(),
        //   onSelect: (item) => handleTranscriptSelect(item),
        // },
        {
          id: 3,
          type: type.NUMBERINPUT,
          isEditable: false,
          isVisible: false,
          lowerValue: () => getLowerTranscriptPercent(),
          onLowerSelect: (item) => updateLowerTranscriptPercent(item),
          value: () => getTranscriptPercent(),
          onSelect: (item) => updateTranscriptPercent(item),
          selectedOption: () => getSelectedTranscript(),
          unit: '',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('SectionwiseAnalytics'),
          onSelect: (item) => updateWeightageFor('SectionwiseAnalytics', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableTranscript(),
          onSelect: (item) => {
            handleTranscriptSwitchChange(item);
          },
        },
      ],
    },
    {
      id: 5,
      fieldName: 'Tone',
      isEnabled: () => getEnableTone(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Energy',
          // title: 'Tone',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => getSelectedTone(),
          onSelect: (item) => handleToneSelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          isEditable: false,
          lowerValue: () => getLowerTonePercent(),
          onLowerSelect: (item) => updateLowerTonePercent(item),
          value: () => getTonePercent(),
          onSelect: (item) => updateTonePercent(item),
          selectedOption: () => getSelectedTone(),
          unit: 'Minimum',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('Tone'),
          onSelect: (item) => updateWeightageFor('Tone', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableTone(),
          onSelect: (item) => {
            handleToneSwitchChange(item);
          },
        },
      ],
    },

    {
      id: 6,
      fieldName: 'Clarity',
      isEnabled: () => getEnableClarity(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Clarity',
        },
        {
          id: 2,
          type: type.OPTIONS,
          options: rangeOptions,
          selected: () => getSelectedClarity(),
          onSelect: (item) => handleClaritySelect(item),
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          isEditable: false,
          lowerValue: () => getLowerClarityPercent(),
          onLowerSelect: (item) => updateLowerClarityPercent(item),
          value: () => getClarityPercent(),
          onSelect: (item) => updateClarityPercent(item),
          selectedOption: () => getSelectedClarity(),
          unit: 'Minimum Percent',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('Clarity'),
          onSelect: (item) => updateWeightageFor('Clarity', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableClarity(),
          onSelect: (item) => {
            handleClaritySwitchChange(item);
          },
        },
      ],
    },
    {
      id: 8,
      fieldName: 'EyeContact',
      isEnabled: () => getEnableEyeContact(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Eye Contact',
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          isEditable: false,
          isVisible: false,
          lowerValue: () => { return 0 },
          onLowerSelect: () => { return 0 },
          value: () => { return 0 },
          onSelect: () => { return 0 },
          selectedOption: () => { return 0 },
          unit: '',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('EyeContact'),
          onSelect: (item) => updateWeightageFor('EyeContact', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableEyeContact(),
          onSelect: (item) => {
            handleEyeContactSwitchChange(item);
          },
        },
      ],
    },
    {
      id: 9,
      fieldName: 'ConfidenceIndex',
      isEnabled: () => getEnableConfidenceIndex(),
      components: [
        {
          id: 1,
          type: type.LABEL,
          title: 'Confidence Index',
        },
        {
          id: 3,
          type: type.NUMBERINPUT,
          isEditable: false,
          isVisible: false,
          lowerValue: () => { return 0 },
          onLowerSelect: () => { return 0 },
          value: () => { return 0 },
          onSelect: () => { return 0 },
          selectedOption: () => { return 0 },
          unit: '',
        },
        {
          id: 4,
          type: type.WEIGHTAGEINPUT,
          value: () => getWeightageFor('ConfidenceIndex'),
          onSelect: (item) => updateWeightageFor('ConfidenceIndex', item),
        },
        {
          id: 5,
          type: type.SWITCH,
          selected: () => getEnableConfidenceIndex(),
          onSelect: (item) => {
            handleConfidenceIndexSwitchChange(item);
          },
        },
      ],
    },

  ];

  const defaultweightage = () => {
    const items = microSkillState.criteria.filter((elem) => elem.check == '1',);
    // console.log("Default Wigth" + items);
    if (items.length > 0) {
      let dr = 100 / items.length;
      console.log('dr 1 = ', dr);

      dr = dr - parseInt(dr) > 0 ? dr.toFixed(2) : parseInt(dr);
      console.log('dr 2 = ', dr);
      return dr;
    }
    return 0;
  };

  const distributeEqualWeightage = () => {
    const weightage = defaultweightage();
    const updatedItems = microSkillState.criteria.map((elem) => {
      if (elem.check == '1') {
        elem.weightageValue = weightage;
        elem.weightage = 1;
      } else {
        elem.weightageValue = 0;
        elem.weightage = 0;
      }
      return elem;
    });
    setMicroSkillState({
      ...microSkillState,
      criteria: updatedItems,
    });
  };

  const updateWeightageFor = (criteria, value) => {
    const wv = defaultweightage();
    const weightage = value / wv;
    const criteriaItems = microSkillState.criteria.map((elem) => {
      if (elem.name == criteria) {
        console.log('updated criteria for = ', criteria);

        elem.weightage = weightage;
        elem.weightageValue = value;
        console.log('updated elem = ', elem);
      }
      return elem;
    });
    setMicroSkillState({
      ...microSkillState,
      criteria: criteriaItems,
    });
  };

  const getWeightageFor = (criteria) => {
    const field = microSkillState.criteria.find(
      (elem) => elem.name == criteria,
    );
    if (!field) return 0
    const { weightageValue = defaultweightage() } = field;
    return weightageValue;
  };

  const initialCheckStateForCriteria = (name) => {
    const item = microSkillState.criteria.find((obj) => obj.name == name);
    return item.check == 1 ? true : false;
  };


  const updateMicroskillForCriterial = (name, value) => {
    const items = microSkillState.criteria.map((obj) => {
      if (obj.name == name) {
        obj.rate = value;
      }
      return obj;
    });
    setMicroSkillState({ ...microSkillState, criteria: items });
  };

  const updateMicroskillCheckForCriterial = (name, value) => {
    const items = microSkillState.criteria.map((obj) => {
      if (obj.name == name) {
        obj.check = value == true ? 1 : 0;
      }
      return obj;
    });
    setMicroSkillState({ ...microSkillState, criteria: items });
  };

  //duration row  state
  const [selectedDuration, setSelectedDuration] = useState(rangeOptions[0]);

  const getSelectedDuration = () => {
    return selectedDuration;
  };

  const [lowerDuration, setLowerDuration] = useState();


  const [duration, setDuration] = useState(() => {
    const duration = microSkillState.criteria.find((obj) => obj.name == 'Duration');
    let rate = duration.rate;
    return rate;
  });
  const getDuration = () => {

    //  minute logic
    // let dr = duration / 60;
    // dr = dr - parseInt(dr) > 0 ? dr.toFixed(2) : parseInt(dr);

    // return dr;

    // new Seconds logic 
    return duration;
  };



  const updateLowerDuration = (item) => {
    setLowerDuration(item);
  };
  const updateDuration = (item) => {
    setDuration(item);
    updateMicroskillForCriterial('Duration', item);
    setMicroSkillState({ ...microSkillState, timeLimit: Number(item) });
    //     setDuration(item * 60);
    //     updateMicroskillForCriterial('Duration', item * 60);
    //     setMicroSkillState({ ...microSkillState, Time_limit: item * 60 });
  };

  const [enableDuration, setEnableDuration] = useState(() => initialCheckStateForCriteria('Duration'));

  const getEnableDuration = () => enableDuration;

  const handleDurationSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('Duration', value);
    setEnableDuration(value);
    distributeEqualWeightage();
  };

  const handleDurationSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedDuration.id) {
      setLowerDuration();
      setDuration();
      setSelectedDuration(selectedOption);
    }
  };

  //speech rate row state
  const [selectedSpeechRate, setSelectedSpeechRate] = useState(rangeOptions[0]);


  const [lowerSpeechRate, setLowerSpeechRate] = useState();

  const [speechRate, setSpeechRate] = useState(() => {
    const speechRate = microSkillState.criteria.find((obj) => obj.name == 'SpeechRate');
    return speechRate.rate;
  });

  const updateLowerSpeechRate = (item) => {
    setLowerSpeechRate(item);
  };
  const updateSpeechRate = (item) => {
    setSpeechRate(item);
    updateMicroskillForCriterial('SpeechRate', item);
  };

  const [enableSpeechRate, setEnableSpeechRate] = useState(() =>
    initialCheckStateForCriteria('SpeechRate'),
  );
  const getEnableSpeechRate = () => {
    return enableSpeechRate;
  };

  const handleSpeechRateSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('SpeechRate', value);
    setEnableSpeechRate(value);
    distributeEqualWeightage();
  };
  const handleSpeechRateSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedSpeechRate.id) {
      setLowerSpeechRate();
      setSpeechRate();
      setSelectedSpeechRate(selectedOption);
    }
  };

  //keyword match row state
  const [selectedKeywordMatch, setSelectedKeywordMatch] = useState(
    rangeOptions[0],
  );
  console.log(rangeOptions[0]);
  const getSelectedKeywordMatch = () => {
    return selectedKeywordMatch;
  };
  const [lowerkeywordMatchPercent, setLowerKeywordMatchPercent] = useState();
  const getLowerKeywordMatchPercent = () => {
    return lowerkeywordMatchPercent;
  };
  const [keywordMatchPercent, setKeywordMatchPercent] = useState(() => {
    const KeyMatch = microSkillState.criteria.find(
      (obj) => obj.name == 'KeyMatch',
    );
    return KeyMatch.rate;
  });
  const getKeywordMatchPercent = () => {
    return keywordMatchPercent;
  };

  const updateLowerKeywordMatch = (item) => {
    setLowerKeywordMatchPercent(item);
  };
  const updateKeywordMatch = (item) => {
    setKeywordMatchPercent(item);
    updateMicroskillForCriterial('KeyMatch', item);
  };

  const [enableKeywordMatch, setEnableKeywordMatch] = useState(() =>
    initialCheckStateForCriteria('KeyMatch'),
  );
  const getEnableKeywordMatch = () => {
    return enableKeywordMatch;
  };

  const handleKeywordMatchSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('KeyMatch', value);
    setEnableKeywordMatch(value);
    distributeEqualWeightage();
  };
  const handleKeywordMatchSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedKeywordMatch.id) {
      setLowerKeywordMatchPercent();
      setKeywordMatchPercent();
      setSelectedKeywordMatch(selectedOption);
    }
  };

  //sequence row state
  const [selectedSequence, setSelectedSequence] = useState(rangeOptions[0]);
  const getSelectedSequence = () => {
    return selectedSequence;
  };
  const [lowerSequencePercent, setLowerSequencePercent] = useState();
  const getLowerSequencePercent = () => {
    return lowerSequencePercent;
  };
  const [sequencePercent, setSequencePercent] = useState(50);
  const getSequencePercent = () => {
    return sequencePercent;
  };

  const updateLowerSequencePercent = (item) => {
    setLowerSequencePercent(item);
  };
  const updateSequencePercent = (item) => {
    setSelectedSequence(item);
  };

  const [enableSequence, setEnableSequence] = useState(true);
  const getEnableSequence = () => {
    return enableSequence;
  };

  const handleSequenceSwitchChange = (value) => {
    setEnableSequence(value);
  };
  const handleSequenceSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedSequence.id) {
      setLowerSequencePercent();
      setSequencePercent();
      setSelectedSequence(selectedOption);
    }
  };

  //new tone state

  const [selectedTone, setSelectedTone] = useState(rangeOptions[0]);
  const getSelectedTone = () => {
    return selectedTone;
  };
  const [lowerTonePercent, setLowerTonePercent] = useState();
  const getLowerTonePercent = () => {
    return lowerTonePercent;
  };
  const [tonePercent, setTonePercent] = useState(() => {
    const item = microSkillState.criteria.find((obj) => obj.name == 'Tone');
    return item.rate;
  });
  const getTonePercent = () => {
    return tonePercent;
  };

  const updateLowerTonePercent = (item) => {
    setLowerTonePercent(item);
  };
  const updateTonePercent = (item) => {
    setTonePercent(item);
    updateMicroskillForCriterial('Tone', item);
  };

  const [enableTone, setEnableTone] = useState(() =>
    initialCheckStateForCriteria('Tone'),
  );
  const getEnableTone = () => {
    return enableTone;
  };

  const handleToneSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('Tone', value);
    setEnableTone(value);
    distributeEqualWeightage();
  };
  const handleToneSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedTone.id) {
      setLowerTonePercent();
      setTonePercent();
      setSelectedTone(selectedOption);
    }
  };

  /*//old tonerow state
  const [selectedTone, setSelectedTone] = useState(rangeOptions[0]);
  const getSelectedTone = () => {
    return selectedTone;
  };
  const [selectedToneType, setSelectedToneType] = useState(toneTypeOptions[0]);
  const getSelectedToneType = () => {
    return selectedToneType;
  };
  const [enableTone, setEnableTone] = useState(true);
  const getEnableTone = () => {
    return enableTone;
  };

  const handleToneSwitchChange = (value) => {
    setEnableTone(value);
  };
  const handleToneSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    setSelectedTone(selectedOption);
  };
  const handleToneTypeSelect = (id) => {
    const selectedOption = toneTypeOptions.find((option) => option.id === id);
    setSelectedToneType(selectedOption);
  };*/

  //new clarity state

  const [selectedClarity, setSelectedClarity] = useState(rangeOptions[0]);
  const getSelectedClarity = () => {
    return selectedClarity;
  };
  const [lowerClarityPercent, setLowerClarityPercent] = useState();
  const getLowerClarityPercent = () => {
    return lowerClarityPercent;
  };
  const [clarityPercent, setClarityPercent] = useState(() => {
    const item = microSkillState.criteria.find(
      (obj) => obj.name == 'Clarity',
    );
    return item.rate;
  });
  const getClarityPercent = () => {
    return clarityPercent;
  };

  const updateLowerClarityPercent = (item) => {
    setLowerClarityPercent(item);
  };
  const updateClarityPercent = (item) => {
    setClarityPercent(item);
    updateMicroskillForCriterial('Clarity', item);
  };

  const [enableClarity, setEnableClarity] = useState(() =>
    initialCheckStateForCriteria('Clarity'),
  );
  const getEnableClarity = () => {
    return enableClarity;
  };

  const handleClaritySwitchChange = (value) => {
    updateMicroskillCheckForCriterial('Clarity', value);
    setEnableClarity(value);
    distributeEqualWeightage();
  };
  const handleClaritySelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedClarity.id) {
      setLowerClarityPercent();
      setClarityPercent();
      setSelectedClarity(selectedOption);
    }
  };

  //old clarityrow state

  /*const [selectedClarity, setSelectedClarity] = useState(rangeOptions[0]);
  const getSelectedClarity = () => {
    return selectedClarity;
  };
  const [selectedClarityType, setSelectedClarityType] = useState(
    clarityTypeOptions[0],
  );
  const getSelectedClarityType = () => {
    return selectedClarityType;
  };
  const [enableClarity, setEnableClarity] = useState(true);
  const getEnableClarity = () => {
    return enableClarity;
  };

  const handleClaritySwitchChange = (value) => {
    setEnableClarity(value);
  };
  const handleClaritySelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    setSelectedClarity(selectedOption);
  };
  const handleClarityTypeSelect = (id) => {
    const selectedOption = clarityTypeOptions.find(
      (option) => option.id === id,
    );
    setSelectedClarityType(selectedOption);
  };
*/
  //new transcriptrow state

  const [selectedTranscript, setSelectedTranscript] = useState(rangeOptions[0]);
  const getSelectedTranscript = () => {
    return selectedTranscript;
  };
  const [lowerTranscriptPercent, setLowerTranscriptPercent] = useState();
  const getLowerTranscriptPercent = () => {
    return lowerTranscriptPercent;
  };
  // const [transcriptPercent, setTranscriptPercent] = useState(() => {
  //   const item = microSkillState.criteria.find(
  //     (obj) => obj.name == 'TranscriptMatch',
  //   );
  //   return item.rate;
  // });
  const getTranscriptPercent = () => {
    // return transcriptPercent;
  };

  const updateLowerTranscriptPercent = (item) => {
    setLowerTranscriptPercent(item);
  };
  const updateTranscriptPercent = (item) => {
    // setTranscriptPercent(item);
    // updateMicroskillForCriterial('TranscriptMatch', item);
  };

  // const [enableTranscript, setEnableTranscript] = useState(() =>initialCheckStateForCriteria('TranscriptMatch'),);
  const [enableTranscript, setEnableTranscript] = useState(true);
  const getEnableTranscript = () => {
    return enableTranscript;
  };

  const handleTranscriptSwitchChange = (value) => {//sectionwise analytics
    updateMicroskillCheckForCriterial('SectionwiseAnalytics', value);
    setEnableTranscript(value);
    distributeEqualWeightage();

  };
  const handleTranscriptSelect = (id) => {
    const selectedOption = rangeOptions.find((option) => option.id === id);
    if (selectedOption.id != selectedTranscript.id) {
      setLowerTranscriptPercent();
      // setTranscriptPercent();
      setSelectedTranscript(selectedOption);
    }
  };



  // Eye Contact State Logic


  const [enableEyeContact, setEnableEyeContact] = useState(() =>
    initialCheckStateForCriteria('EyeContact'),)

  const getEnableEyeContact = () => {
    return enableEyeContact;
  }

  const handleEyeContactSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('EyeContact', value);
    setEnableEyeContact(value);
    distributeEqualWeightage();
    console.log(value);
  };


  // Confidence Index State Logic


  const [enableConfidenceIndex, setEnableConfidenceIndex] = useState(() =>
    initialCheckStateForCriteria('ConfidenceIndex'),)

  const getEnableConfidenceIndex = () => {
    return enableConfidenceIndex;
  }

  const handleConfidenceIndexSwitchChange = (value) => {
    updateMicroskillCheckForCriterial('ConfidenceIndex', value);
    setEnableConfidenceIndex(value);
    distributeEqualWeightage();
  };


  useEffect(() => {
    if (enableClarity && enableEyeContact && enableTone) {
      handleConfidenceIndexSwitchChange(1)
    } else {
      handleConfidenceIndexSwitchChange(0)
    }
  }, [enableClarity, enableEyeContact, enableTone])


  const classes = styles();

  const [forward_xls_toast, set_forward_xls_toast] = useState(false);
  const [forward_xls_toast_msg, set_forward_xls_toast_msg] = useState('');

  const isWeightageDistributionCorrect = () => {
    const total = microSkillState.criteria.reduce(
      (a, b) => a + parseFloat(b.weightageValue),
      0,
    );
    return Math.round(total) == 100;
  };

  async function processRTFWithImages(rtfContent = '', uploadImageToS3) {
    try {
      // Regular expression to find img tags with base64 content
      const imgRegex = /<img[^>]+src="data:image\/([a-zA-Z]+);base64,([^"]+)"[^>]*>/g;
      let modifiedRTF = rtfContent;
      const uploadPromises = [];

      // Find all matches and process them
      const matches = [...rtfContent.matchAll(imgRegex)];

      for (const match of matches) {
        const [fullMatch, imageType, base64Data] = match;

        // Convert base64 to file
        const byteString = atob(base64Data);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], { type: `image/${imageType}` });
        const file = new File([blob], `image.${imageType}`, { type: `image/${imageType}` });

        // Use existing upload function and store promise
        // const uploadPromise = uploadImageToS3(file)
        //   .then(location => {
        //     // Replace base64 content with S3 URL in RTF
        //     modifiedRTF = modifiedRTF.replace(
        //       fullMatch,
        //       `<img src="${location}" alt="uploaded-image" />`
        //     );
        //   });

        const json = { companyId, microskillId, challengeId, 'LAMediaFiles': file }
        const mediaPayload = await commonUtil.jsonToFormData(json);
        const uploadPromise = challengesServices.uploadMediaResources(mediaPayload).then(res => {
          console.log('res = ', res);

          const location = res.data.data?.mediaResources[0]?.location ?? '';
          if (res.status === 201 && !isEmpty(location)) {
            // Replace base64 content with S3 URL in RTF
            modifiedRTF = modifiedRTF.replace(fullMatch, `<img src="${location}" alt="uploaded-image" />`);
          }
        });

        uploadPromises.push(uploadPromise);
      }

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);

      return {
        success: true,
        rtfContent: modifiedRTF,
        imageCount: matches.length
      };

    } catch (error) {
      console.error('Error processing RTF content:', error);
      return {
        success: false,
        error: error.message,
        rtfContent: rtfContent
      };
    }
  }



  const handleUpdateSpotlight = async (payload, challengeId) => {
    const res = await challengesServices.updateTwoWaySpotlight(payload);
    if (res.status === 200 || res.status === 201) {
      let oldName = query.get("challengeName");
      let newName = microSkillState.challengeName;
      let updateChallengePayload = {
        companyId: companyId,
        microskillId: microskillId,
        challengeId: challengeId,
        status: "COMPLETED"
      }
      if (oldName !== newName) updateChallengePayload.name = microSkillState.challengeName;
      await challengesServices.updateChallenge(updateChallengePayload);
      setsavingInProgress(false);
      globalStates.handleProgressDialog(false);

      localStorage.setItem('isChallengeUpdated', true);
      router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`);

    } else {
      setsavingInProgress(false);
      globalStates.handleProgressDialog(false);
      globalStates.handleToast(true, 'Something went wrong', 'error');

    }
  }

  const doesCriteriaContains = (criteria, key = '') => {
    if (isEmpty(criteria)) return false;
    let foundItem = criteria.find((item) => (item.name?.toUpperCase() === key?.toUpperCase()));
    if (isEmpty(foundItem)) return false;

    let contains = !isEmpty(foundItem) && (foundItem?.check == 1);
    return contains;
  }

  const handleSave = async () => {

    // console.log('microSkillState = ', microSkillState);
    const areKeywordsEnabled = doesCriteriaContains(microSkillState.criteria, 'KeyMatch')
    // console.log('areKeywordsEnabled = ', areKeywordsEnabled);

    if (areKeywordsEnabled && isEmpty(microSkillState.keywords)) {
      globalStates.handleToast(true, 'Please add keywords or disable the keyword match criteria', 'error');
      return;
    }

    if (!isWeightageDistributionCorrect()) {
      globalStates.handleToast(true, 'Total weightage must be 100', 'error');
      return;
    }

    if (isEmpty(microSkillState.timeLimit)) {
      globalStates.handleToast(true, 'Timelimit cannot be empty', 'error');
      return;
    }
    if (!isEmpty(microSkillState.timeLimit) && parseInt(microSkillState.timeLimit) <= 30) {
      globalStates.handleToast(true, 'Minimum timelimit should be 30 seconds', 'error');
      return;
    }
    if (isEmpty(microSkillState.maxAttempts)) {
      globalStates.handleToast(true, 'Attempts cannot be empty', 'error');
      return;
    }
    if (!isEmpty(microSkillState.maxAttempts) && parseInt(microSkillState.maxAttempts) <= 0) {
      globalStates.handleToast(true, 'Minimum 1 attempt required', 'error');
      return;
    }

    if (isEmpty(microSkillState.passPercent)) {
      globalStates.handleToast(true, 'Pass percent cannot be empty', 'error');
      return;
    }
    if (!isEmpty(microSkillState.passPercent) && parseInt(microSkillState.passPercent) <= 0) {
      globalStates.handleToast(true, 'Minimum pass percent should be 70', 'error');
      return;
    }

    if (!isEmpty(microSkillState.passPercent) && parseInt(microSkillState.passPercent) > 100) {
      globalStates.handleToast(true, 'Maximum pass percent should be 100', 'error');
      return;
    }



    // return
    setsavingInProgress(true);

    globalStates.handleProgressDialog(true, 'Please wait..');

    let payload = { ...microSkillState, companyId, microskillId, challengeId };

    // Example usage:
    const result = await processRTFWithImages(microSkillState.formatted);
    if (result.success) {
      console.log('Modified RTF result:', result);
      payload = { ...payload, formatted: result.rtfContent };
    } else {
      console.error('Error RTF result:', result);
      globalStates.handleToast(true, 'Failed to upload image', 'error');
      globalStates.handleProgressDialog(false);
      return
    }

    if (microSkillState.convertToAudio && microSkillState.scenario?.length && isEmpty(microSkillState.scenarioMediaPath)) {
      const response = await textToSpeechServices.convertTextToSpeech({ text: microSkillState.scenario, name: 'en-IN-KunalNeural', gender: 'Male', lang: 'en-IN' });
      if (response?.status === 200) {
        const blob = new Blob([response.data], { type: 'audio/wav' });
        const file = new File([blob], 'text-to-speech.wav', { type: 'audio/wav' });
        const json = { companyId, microskillId, challengeId, 'LAMediaFiles': file }
        const mediaPayload = await commonUtil.jsonToFormData(json);
        const res = await challengesServices.uploadMediaResources(mediaPayload);
        if (res.status === 200 || res.status === 201) {
          const url = res.data.data?.mediaResources[0]?.location ?? '';
          payload.scenarioMediaPath = url;
        }
      } else {
        globalStates.handleProgressDialog(false);
        globalStates.handleToast(true, 'Failed to convert to audio', 'error');
        return
      }
    }
    if (payload.keywords.length) {
      let temp = [];
      payload.keywords.forEach(element => {
        let tempObj = {
          id: element.id,
          head: element.head,
          keywords: element.keywords
        }
        temp.push(tempObj)
      });
      payload.keywords = temp;
    }
    // return
    if (isEdit) {
      handleUpdateSpotlight(payload, challengeId);
      return
    }
    const res = await challengesServices.addTwoWaySpotlight(payload);
    if (res.status === 200 || res.status === 201) {
      localStorage.setItem('isChallengeCreated', true)
      let oldName = query.get("challengeName");
      let newName = microSkillState.challengeName;
      let updateChallengePayload = {
        companyId: companyId,
        microskillId: microskillId,
        challengeId: challengeId,
        status: "COMPLETED"
      }
      if (oldName !== newName) updateChallengePayload.name = microSkillState.challengeName;
      await challengesServices.updateChallenge(updateChallengePayload);

      setsavingInProgress(false);
      globalStates.handleProgressDialog(false, 'Please wait..');

      router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`);
    } else {
      setsavingInProgress(false);
      globalStates.handleProgressDialog(false, 'Please wait..');
      globalStates.handleToast(true, 'Something went wrong', 'error');

    }

  };

  const CustomTableCell = TableCell;

  const CustomTableRow = TableRow;

  const renderCellComponent = (component, index, isEnabled) => {
    let selectedOption = null;
    const { isEditable = true, isVisible = true } = component;
    if ('selectedOption' in component) {
      selectedOption = component.selectedOption();
    }
    switch (component.type) {
      case type.LABEL:
        return (
          <CustomTableCell align="left" style={{ maxWidth: '25%' }}>
            <SmallFontLabel style={{ opacity: !isEnabled ? 0.4 : 1 }}>
              {component.title}
            </SmallFontLabel>
          </CustomTableCell>
        );
        // case type.OPTIONS:
        return (
          <CustomTableCell
            align="left"
            style={index == 2 ? { maxWidth: '50%' } : { maxWidth: '25%' }}>
            {isEnabled && (
              <div
                style={{ width: '100%', maxWidth: '160px', minWidth: '100px' }}>
                <SimpleFormSelect
                  onSelect={(e) => component.onSelect(e)}
                  autoClose={true}
                  options={component.options}
                  selected={
                    (component.selected() &&
                      component.selected().display_text) ||
                    ''
                  }
                  text={
                    (component.selected() &&
                      component.selected().display_text) ||
                    ''
                  }
                />
                {index == 2 && <SmallFontLabel></SmallFontLabel>}
              </div>
            )}
          </CustomTableCell>
        );
      case type.WEIGHTAGEINPUT:
        return (
          <CustomTableCell align="left" style={{ maxWidth: '10%' }}>
            {isEnabled ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <NumericInput
                  width={'100%'}
                  maxWidth={'85px'}
                  minWidth={'85px'}
                  type="number"
                  min={0}
                  max={100}
                  textAlign={'center'}
                  stepperbg={'transparent'}
                  value={component.value()}
                  backgroundColor={'transparent'}
                  onChangeText={(e) => component.onSelect(e)}
                />
              </div>
            ) : null}
          </CustomTableCell>
        );
      case type.NUMBERINPUT:
        return (
          <CustomTableCell align="left" style={{ maxWidth: '50%' }}>
            {isEnabled ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {selectedOption && selectedOption.id == 3 ? (
                  <>
                    <div style={{ marginRight: '10px' }}>
                      <NumericInput
                        width={'100%'}
                        maxWidth={'85px'}
                        minWidth={'85px'}
                        type="number"
                        backgroundColor={'transparent'}
                        placeholder={'Lower range'}
                        min={Number.MIN_VALUE}
                        textAlign={'center'}
                        max={parseInt(
                          component.value()
                            ? component.value()
                            : Number.MAX_VALUE,
                        )}
                        stepperbg={'transparent'}
                        value={component.lowerValue()}
                        onChangeText={(e) =>
                          component.onLowerSelect(parseInt(e))
                        }
                      />
                    </div>
                    <NumericInput
                      width={'100%'}
                      maxWidth={'85px'}
                      minWidth={'85px'}
                      type="number"
                      textAlign={'center'}
                      placeholder={'Upper range'}
                      stepperbg={'transparent'}
                      backgroundColor={'transparent'}
                      min={parseInt(
                        component.lowerValue() ? component.lowerValue() : 0,
                      )}
                      max={Number.MAX_VALUE}
                      value={component.value()}
                      onChangeText={(e) => component.onSelect(e)}
                    />
                  </>
                ) : !isEditable ? (
                  <div
                    style={{
                      width: '100%',
                      maxWidth: '85px',
                      minWidth: '85px',
                      border: '0.20000000298023224px dashed #707070',
                      borderRadius: 2,
                      height: 27.3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      opacity: isVisible ? "1" : "0",
                    }}>
                    <label
                      style={{
                        font: 'normal normal normal 12px Open Sans',
                        color: '#707070',
                        marginRight: '10px',
                      }}>
                      {component.value()}
                    </label>
                  </div>
                ) : (
                  <NumericInput
                    width={'100%'}
                    maxWidth={'85px'}
                    minWidth={'85px'}
                    type="number"
                    min={0}
                    textAlign={'center'}
                    stepperbg={'transparent'}
                    value={component.value()}
                    backgroundColor={'transparent'}
                    onChangeText={(e) => component.onSelect(e)}
                  />
                )}
                <SmallFontLabel
                  style={{
                    marginLeft: '10px',
                    opacity: '0.75',
                    color: '#484848',
                    fontSize: '0.6rem',
                  }}>
                  {component.unit}
                </SmallFontLabel>
              </div>
            ) : null}
          </CustomTableCell>
        );
      case type.SWITCH:
        return (
          <CustomTableCell
            align="left"
            style={{ maxWidth: '15%', justifyContent: 'center' }}>
            <Switch
              checked={component.selected()}
              onChange={(e) => component.onSelect(e)}
            />
          </CustomTableCell>
        );

      case type.BIDIRECTIONALSWITCH:
        return (
          <CustomTableCell align="left" style={{ maxWidth: '25%' }}>
            {isEnabled ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}>
                <SmallFontLabel
                  style={{
                    marginLeft: '10px',
                    opacity: '0.75',
                    color: '#484848',
                    fontWeight: '300',
                    fontSize: '0.60rem',
                  }}>
                  {component.leftOption}
                </SmallFontLabel>
                <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                  <ToggleSwitch
                    bidirectional={'true'}
                    checked={component.selected()}
                    onChange={(e) => {
                      component.onSelect(!component.selected());
                    }}
                  />
                </div>
                <SmallFontLabel
                  style={{
                    marginLeft: '10px',
                    opacity: '0.75',
                    color: '#484848',
                    fontWeight: '300',
                    fontSize: '0.60rem',
                  }}>
                  {component.rightOption}
                </SmallFontLabel>
              </div>
            ) : null}
          </CustomTableCell>
        );

      default:
        return <></>;
    }
  };

  const renderRow = tableItems.map((item) => {
    // console.log('fieldName', item.fieldName);
    // console.log('item components', item.components);
    return (

      <CustomTableRow >
        {item.components.map((component, index) =>
          renderCellComponent(component, index, item.isEnabled()),
        )}
      </CustomTableRow>
    )
  }
  );



  return (
    <>
      {/* <div className={classes.heading_container}>
        <HeadingLabel>Create Roleplay Challenge Below</HeadingLabel>
      </div> */}
      <FlexRowDiv className={classes.challangeContainer} style={{ gap: '2rem' }}>
        <div className={classes.leftContainerPage4}>
          <div>
            <HeadingLabel>Completion Criteria</HeadingLabel>
          </div>
          <div
            className='scrollbar'
            style={{
              width: '100%',
              maxHeight: "calc(80vh - 12rem)",
              overflow: 'auto',
            }}>
            <Table>
              <TableBody>
                {renderRow}
              </TableBody>
            </Table>
          </div>
          {/* {constants.SPOTLIGHT_CONTEXTUAL_MATCH_ACCESS.includes(companyId) && showContextualMatch ?
              <div style={{ marginLeft: '0.8rem' }}>
                <Checkbox
                  id='contextual-match'
                  checked={contextualMatch}
                  onChange={handleContextualMatch}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, color: contextualMatch && '#f4511e !important' }}
                />
                <Label labelText={'Contextual match'} htmlFor={'contextual-match'} />
              </div> : null
            } */}

        </div>
        <div className={classes.rightContainerPage4}>
          {/* Attempts */}
          <FormControl className={classes.formControlWrapper}>
            <Label>Attempts</Label>
            <NumericInput
              width={classes.full_Width}
              type="number"
              min={1}
              value={microSkillState.maxAttempts}
              unit={'Max.'}
              onChangeText={(value) => setMicroSkillState({ ...microSkillState, maxAttempts: isNaN(parseInt(value)) ? '' : parseInt(value) })}
            />
          </FormControl>

          {/* Pass percent */}
          <FormControl className={classes.formControlWrapper}>
            <Label>Pass Percentage</Label>
            <NumericInput
              width={classes.full_Width}
              type="number"
              min={1}
              max={100}
              value={microSkillState.passPercent}
              unit={'Percent'}
              onChangeText={(value) => setMicroSkillState({ ...microSkillState, passPercent: isNaN(parseInt(value)) ? '' : parseInt(value) })}
            />
          </FormControl>

          {/* Qualitative feedback */}
          <div className={classes.switchContainer}>
            <Label style={{ paddingBottom: 0 }}>
              Qualitative feedback
            </Label>
            <ToggleSwitch
              checked={microSkillState.qualitativeFeedback}
              onChange={() => setMicroSkillState({ ...microSkillState, qualitativeFeedback: !microSkillState.qualitativeFeedback })}
              name="qualitativeFeedback"
            />
          </div>
        </div>
      </FlexRowDiv>

      <FormControl className={classes.action_btn_container}>
        <FlatClearButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
            marginRight: '2rem'
          }}
          onClick={() => {
            // saveDraft();
            setCurrentPage(pages[2]);
          }}>
          Back
        </FlatClearButton>
        {savingInProgress ?

          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            sx={{
              minWidth: '130px',
              width: '134px',
              height: '47px',
              padding: '15px 30px',
              border: '2px solid rgba(0, 0, 0, 0.12) !important',
              fontWeight: 700,
              fontFamily: 'Montserrat',
            }}
          >
            SAVING...
          </LoadingButton> :
          <FlatPrimaryButton
            style={{
              minWidth: '130px',
              width: '150px',
              padding: '15px 30px',
            }}
            onClick={handleSave}>
            SAVE
          </FlatPrimaryButton>}
      </FormControl>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={forward_xls_toast}
        autoHideDuration={6000}
        onClose={() => {
          set_forward_xls_toast(false);
          set_forward_xls_toast_msg('');
        }}
        message={<span id="message-id">{forward_xls_toast_msg}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => {
              set_forward_xls_toast(false);
              set_forward_xls_toast_msg('');
            }}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};

export default Page4_CompletionCriteria;

const custom_styles = makeStyles({});


