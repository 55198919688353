import axios from 'axios'

const subscriptionKey = 'b17a8939c2e84dc7b7f44519fb3cd169';

function htmlEncode(text) {
    const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;'
    };

    return String(text).replace(/[&<>"'\/]/g, (match) => entityMap[match])
}

const convertTextToSpeech = async ({ text = "I'm excited to try text to speech!", name = 'en-US-ChristopherNeural', gender = 'Male', lang='en-US' }) => {
    const url = 'https://centralindia.tts.speech.microsoft.com/cognitiveservices/v1'
    const headers = {
        'Ocp-Apim-Subscription-Key': subscriptionKey,
        'Content-Type': 'application/ssml+xml',
        'X-Microsoft-OutputFormat': 'riff-24khz-16bit-mono-pcm',
    };

    const ssml = `<speak version='1.0' xml:lang='${lang}'> <voice xml:lang='${lang}' xml:gender='${gender}' name='${name}'> ${htmlEncode(text)} </voice> </speak>`;

    try {
        const response = await axios.post(url, ssml, { headers, responseType: 'arraybuffer' });
        return response;
    } catch (error) {
        console.error('Error converting text to speech:', error);
        return error.response;
    }
};

const getListOfVoices = async () => {
    const url = `https://centralindia.tts.speech.microsoft.com/cognitiveservices/voices/list?Ocp-Apim-Subscription-Key=${subscriptionKey}`;

    try {
        const response = await axios.get(url);
        return response;
    } catch (error) {
        return error.response;
    }
}

export default {
    convertTextToSpeech,
    getListOfVoices
}