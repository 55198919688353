import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    headerDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    toggleDiv: {
        display: 'flex',
        alignItems: 'center',
    },
    buttonContainerDiv: {
        display: 'flex',
        justifyContent: 'center'
    },
});

export default useStyles;