import React from 'react'

//mui
import { makeStyles } from "@mui/styles";
// import { Dialog } from '@material-ui/core';

//new
import Dialog from '@mui/material/Dialog';


import styled from 'styled-components';

//components
import CustomButton from '../custom-styled/button';


const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const useStyles = makeStyles(() => ({
    main: {
        border: '1px solid',
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgb(0 0 0 / 75%)',
            backdropFilter: 'blur(4px)',
        }

    },
    btnBox: {
        '& .MuiButtonBase-root': {
            border: '1px solid #f4511e',
            borderRadius: '2px',
            backgroundColor: '#f4511e',
            color: '#fff',
            borderColor: '#f4511e',
            fontSize: '0.6rem',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            height: '2.3rem',
            width: '8rem'
        }
    }
}));

const SuccessDialog = ({
    successDialogOpen,
    setSuccessDialogOpen,
    dialogText = '',
    handleClick,
    buttonText = 'Okay',
    bgOpacity = 75
}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={successDialogOpen}
            className={classes.main}
            sx={{
                '& .MuiBackdrop-root': {
                    backgroundColor: `rgb(0 0 0 / ${bgOpacity}%)`,
                    backdropFilter: 'blur(4px)',
                }
            }}
            onClose={() => {
                return

                setSuccessDialogOpen(false);
            }}>
            <ProgressContainer style={{ minWidth: '310px', maxWidth: '450px' }} >
                <img
                    src={'/images/icons/circleCheck.png'}
                    style={{ width: '100px', height: '100px' }}
                />
                <div style={{ margin: '12px 0' }}>
                    <ProgressText>{dialogText}</ProgressText>
                </div>
                <div style={{ margin: '12px 0' }} className={classes.btnBox}>
                    <CustomButton
                        variant='filled'
                        textColor='#fff'
                        borderColor='#f4511e'
                        bgcolor='#f4511e'
                        fs="0.6rem"
                        fw={700}
                        ls={0}
                        btnText={buttonText}
                        handleClick={handleClick}
                        height={'2rem'}
                    />
                </div>
            </ProgressContainer>
        </Dialog>
    )
}

export default SuccessDialog