import React from 'react';
import ReactQuill from 'react-quill';
import '../../../node_modules/react-quill/dist/quill.snow.css';

const Editor = (props) => {
  const { readOnly = false, showModules = true, preserveWhitespace = true } = props;

  return (
    <ReactQuill
      ref={props.quillRef}
      theme={props.theme}
      onChange={props.handleChange}
      onBlur={props.onBlur}
      value={props.value}
      modules={showModules == true ? Editor.modules : {}}
      formats={showModules == true ? Editor.formats : {}}
      //   bounds={'.app'}
      style={props.style}
      className={props.className}
      placeholder={props.placeholder}
      readOnly={readOnly}
      preserveWhitespace={preserveWhitespace}
    />
  );
};

export default Editor;

const toolbarOptions1 = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],
  ['link', 'image', 'video', 'formula'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['clean']                                         // remove formatting button
];
const toolbarOptions = [
  [{ font: [] }],
  // [{ size: [] }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ['bold', 'italic', 'underline', 'strike','image'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  // [{ indent: '-1' }, { indent: '+1' }, { indent: '0' }],
]

Editor.modules = {
  toolbar: toolbarOptions,
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  'font',
  // 'size',
  'color',
  'background',
  'align',
  'bold',
  'italic',
  'underline',
  'strike',
  'image',
  'list',
  // 'header'
];




// Editor.modules = {
//   toolbar: [
//     [{ font: [] }],
//     [{ size: [] }],
//     ['bold', 'italic', 'underline'],
//     [{ indent: '-1' }, { indent: '+1' }, { indent: '0' }],
//     ],
//   clipboard: {
//     // toggle to add extra line breaks when pasting HTML:
//     matchVisual: false,
//   },
// };

// Editor.formats = ['font', 'size', 'bold', 'italic', 'underline', 'indent'];