import React, { useContext } from 'react';
import { useParams } from "react-router-dom";

//mui
import { makeStyles } from "@mui/styles";
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';

//constants
import constants from '../../constants';

//utils
import useQuery from '../../utils/useQuery';
import useRouter from '../../utils/useRouter';

//global-states
import { GlobalStates } from '../../App';


const AppBar = (props) => {
    const { handleDrawerOpen, open, drawerWidth = 64 } = props;
    const classes = useStyles();
    const router = useRouter();
    const query = useQuery();
    const { companyId } = useParams();
    const [globalStates] = useContext(GlobalStates)
    const companyName = localStorage.getItem('company-name');
    const microskillName = query.get('microskillName');
    const enableBackFor = ['/microskill-studio', '/projects', 'tms', 'spotlight', 'scorm', 'qr-based-challenge','/bulletin'] //'/manage-microskills', REMOVED BACK BUTTON FOR MANAGE-MS PAGE
    let showBackOption = false;
    let myPath = window.location.pathname.split('/')[1];
    myPath = `/${myPath}`
    if (myPath === '/drive') {
        let tmsPath = window.location.pathname.split('/');
        let enableBackInTmsFor = ['edit', 'create-new-course', 'training-tool', 'create-new-training-session']
        for (let i = 0; i < enableBackInTmsFor.length; i++) {
            if (tmsPath.includes(enableBackInTmsFor[i])) {
                myPath = 'tms'
                break
            }
        }
        // console.log('tmsPath = ', tmsPath);

    }
    if (myPath === '/microskill-studio') {
        let path = window.location.pathname.split('/');
        // console.log('path = ', path);
        if (path.includes('spotlight') || path.includes('two-way-spotlight')) {
            myPath = 'spotlight'
        };
        if (path.includes('scorm')) {
            myPath = 'scorm'
        };
        if (path.includes('qr-based-challenge')) {
            myPath = 'qr-based-challenge'
        };

    }
    // console.log('myPath = ', myPath)
    if (enableBackFor.includes(myPath)) {
        showBackOption = true;
    }
    let userType = localStorage.getItem('user-type');
    if (myPath === '/projects' && userType !== 'superAdmin') {
        showBackOption = false;
    }

    // console.log('showBackOption = ', showBackOption);

    const handleBack = () => {
        // console.log('handleBack myPath = ', myPath);
        if (myPath === '/projects') {
            router.history.push('/companies');
            // console.log('ITS PROJECT PAGE');
            return;
        }
        if (myPath === '/manage-microskills') {
            router.history.push(`/projects/${companyId}`);
            // console.log('ITS MANAGE MICROSKILL PAGE');
            return;
        }
        if (myPath === '/microskill-studio') {
            const c_id = query.get('c_id');
            const p_id = query.get('p_id');
            router.history.push(`/manage-microskills/${c_id}/${p_id}`);
            // console.log('ITS CREATE/EDIT MICROSKILL PAGE');
            return;
        }
        router.history.goBack();
    }

    const TopBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        width: `100%`,
        marginLeft: 0,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: '1s',
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth})`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: '1s',
            }),
        }),
    }));
    return (
        <TopBar position="fixed" open={open} className={classes.topBar}>
            <Toolbar>
                {!open ? <IconButton
                    className={classes.iconBtn}
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                        marginRight: 4,
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton> : null
                }
                <Typography variant="h6" noWrap component="div">
                    {
                        showBackOption ?
                            <img onClick={handleBack} src='/images/gba-assets/left-arrow.svg' alt='left-arrow' />
                            : null
                    }
                    {globalStates.appBarTitle}
                </Typography>
                {
                    globalStates.showMsName ?
                        <div className={classes.column2}>
                            <h1>Microskill Name :</h1>
                            <h4>{microskillName}</h4>

                        </div> : userType === "companyAdmin" && <div className={classes.column2}>
                            <h1>Logged in :</h1>
                            <h4>{companyName}</h4>

                        </div>
                }

            </Toolbar>
        </TopBar>
    )
}

export default AppBar;


const useStyles = makeStyles(() => ({
    topBar: {
        // border : '1px solid',
        backgroundColor: '#F9F9F9 !important',
        boxShadow: 'none !important',
        color: 'black',
        // width: '100%',
        height: `${constants.HEADER_HEIGHT} !important`,
        opacity: 1,
        '& .MuiToolbar-root': {
            position: 'relative',
            minHeight: '3.4rem',
            gap: '1rem',
            padding: 0,
            '& .MuiButtonBase-root': {
                padding: '8px !important',
                display: 'flex',
                margin: '0px 13px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '20px'
            },
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                color: "rgb(0, 0, 0, 0.9)",
                fontFamily: "Montserrat",
                fontSize: "1rem",
                fontWeight: "800",
                // marginLeft: '0rem'
                '& > img': {
                    cursor: 'pointer',
                    transition: 'width 2s',
                    marginRight: '1rem',
                    '&:hover': {
                        width: '1.2rem',
                        marginRight: '0.9rem'
                    }
                },
            }
        }
    },
    iconBtn: {
        '&:hover': {
            backgroundColor: 'rgba(204, 204, 204, 0.3)',
            '& img': {
                filter: 'invert(44%) sepia(200%) saturate(450%) hue-rotate(330deg) brightness(80%) contrast(290%)',
            },
            '& .MuiSvgIcon-root': {
                filter: 'invert(44%) sepia(200%) saturate(450%) hue-rotate(330deg) brightness(80%) contrast(290%)',
            }
        }
    },
    column2: {
        // border: '1px solid',
        position: 'absolute',
        right: 0,
        height: '100%',
        // width: '16%',
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        '& > h1,h4': {
            color: "rgb(0, 0, 0, 0.8)",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        '& > h4': {
            paddingLeft: '0.5rem',
            // color: "rgb(112, 112, 112)",
            color: "#4F0060",
            fontWeight: 700,
        }
    }
}));
