import React, { useState, useContext, useRef } from 'react';
import styles, { FlexRowDiv } from './style';

//mui
import { Radio } from '@mui/material'


//components
import FormControl from './components/FormControl';
import Label from '../custom-styled/label';
import ToggleSwitch from './components/ToggleSwitch';
import FlatPrimaryButton from './components/FlatPrimaryButton';
import FlatClearButton from './components/FlatClearButton';
import SimpleFormSelect from './components/SimpleFormSelect';

//constants
import { industries, personalities, temperaments, endUserRoles } from './constants';


//utils
import isEmpty from '../../utils/isEmpty';
import commonUtil from '../../utils/commonUtil';

//context
import { GlobalStates } from '../../App';

export const Page2_challange = ({
  setCurrentPage,
  pages,
  microSkillState,
  setMicroSkillState,
}) => {
  const classes = styles();
  const promptRefs = useRef([]);
  const [globalStates] = useContext(GlobalStates);



  const nextAction = () => {
    console.log('microSkillState page 2 = ', microSkillState);
    if (isEmpty(microSkillState.personality)) {
      globalStates.handleToast(true, 'Please select the Personality', 'error');
      return;
    }
    if (isEmpty(microSkillState.industry)) {
      globalStates.handleToast(true, 'Please select the Industry', 'error');
      return;
    }
    if (isEmpty(microSkillState.endUserRole)) {
      globalStates.handleToast(true, 'Please select the End User Role', 'error');
      return;
    }
    if (isEmpty(microSkillState.temperament)) {
      globalStates.handleToast(true, 'Please select the Temperament', 'error');
      return;
    }

    setCurrentPage(pages[2]);
  };

  const handlePromptChange = (e, item) => {
    const { value } = e.target;

    const newPrompt = microSkillState.prompt.map((v) => {
      if (v.id === item.id) {
        return { ...v, content: value };
      }
      return v;
    });

    setMicroSkillState({ ...microSkillState, prompt: newPrompt });
  }



  // Example usage:

  const handleIndustryChange = (values) => {
    // let industryId = 2;
    // const newPrompt = microSkillState.prompt.map((v) => {
    //   if (v.id === industryId) {
    //     const updatedPrompt =  commonUtil.replaceSubstring(values, microSkillState.industry, v.content);
    //     return { ...v, content: updatedPrompt };
    //   }
    //   return v;
    // });

    setMicroSkillState({ ...microSkillState, industry: values });
  }

  const handleEndUserRoleChange = (values) => {
    let boundariesId = 2;
    let conditionalRulesId = 3;
    const newPrompt = microSkillState.prompt.map((v) => {
      if (v.id === boundariesId || v.id === conditionalRulesId) {
        const updatedPrompt = commonUtil.replaceSubstring(values, microSkillState.endUserRole, v.content);
        const startIndex = updatedPrompt.indexOf(values);
        const endIndex = startIndex + values.length;


        if (v.id === boundariesId && startIndex !== -1 && promptRefs.current[boundariesId]) {
          setTimeout(() => {
            promptRefs.current[boundariesId].focus();
            promptRefs.current[boundariesId].setSelectionRange(startIndex, endIndex);
          }, 0);
        }
        return { ...v, content: updatedPrompt };
      }
      return v;
    });

    setMicroSkillState({ ...microSkillState, endUserRole: values, prompt: newPrompt });
  }

  const handlePersonalityChange = (values) => {
    let personaId = 0;
    let boundariesId = 2;

    const newPrompt = microSkillState.prompt.map((v) => {
      if (v.id === personaId || v.id === boundariesId) {
        const updatedPrompt = commonUtil.replaceSubstring(values, microSkillState.personality, v.content);

        const startIndex = updatedPrompt.indexOf(values);
        const endIndex = startIndex + values.length;

        if (v.id === personaId && startIndex !== -1 && promptRefs.current[personaId]) {
          setTimeout(() => {
            promptRefs.current[personaId].focus();
            promptRefs.current[personaId].setSelectionRange(startIndex, endIndex);
            promptRefs.current[personaId].scrollTo(0, 0, "ease");
          }, 0);
        }
        return { ...v, content: updatedPrompt };
      }
      return v;
    });

    setMicroSkillState({ ...microSkillState, personality: values, prompt: newPrompt })
  }

  const handleTemperamentChange = (values) => {
    let personaId = 0;
    const newPrompt = microSkillState.prompt.map((v) => {
      if (v.id === personaId) {
        const updatedPrompt = commonUtil.replaceSubstring(values, microSkillState.temperament, v.content);

        const startIndex = updatedPrompt.indexOf(values);
        const endIndex = startIndex + values.length;

        if (startIndex !== -1 && promptRefs.current[personaId]) {
          setTimeout(() => {
            promptRefs.current[personaId].focus();
            promptRefs.current[personaId].setSelectionRange(startIndex, endIndex);
          }, 0);
        }
        return { ...v, content: updatedPrompt };
      }
      return v;
    });

    setMicroSkillState({ ...microSkillState, temperament: values, prompt: newPrompt })
  }

  const handleGenderChange = (gender) => {
    setMicroSkillState({ ...microSkillState, gender })
  }


  return (
    <>
      <FlexRowDiv className={classes.challangeContainer}>
        <div className={classes.leftContainer}>
          {/* personality */}
          <FormControl className={classes.formControlWrapper}>
            <Label labelText="AI persona" required={true} p="0 0 0.8rem 0" />

            <SimpleFormSelect
              width={classes.full_Width}
              autoClose={true}
              options={personalities}
              text={personalities.filter((v) => v.id === microSkillState.personality)[0]?.display_text ?? 'Select Personality'}
              onSelect={handlePersonalityChange}
            />
          </FormControl>
          {/* industry */}
          <FormControl className={classes.formControlWrapper}>
            <Label labelText="Industry" required={true} p="0 0 0.8rem 0" />

            <SimpleFormSelect
              width={classes.full_Width}
              autoClose={true}
              options={industries}
              text={industries.filter((v) => v.id === microSkillState.industry)[0]?.display_text ?? 'Select Industry'}
              onSelect={handleIndustryChange}
            />
          </FormControl>
          {/* End user role */}
          <FormControl className={classes.formControlWrapper}>
            <Label labelText="End user role" required={true} p="0 0 0.8rem 0" />

            <SimpleFormSelect
              width={classes.full_Width}
              autoClose={true}
              options={endUserRoles}
              text={endUserRoles.filter((v) => v.id === microSkillState.endUserRole)[0]?.display_text ?? 'Select End User Role'}
              onSelect={handleEndUserRoleChange}
            />
          </FormControl>



          {/* temperament */}
          <FormControl className={classes.formControlWrapper}>
            <Label labelText="Temperament" required={true} p="0 0 0.8rem 0" />

            <SimpleFormSelect
              width={classes.full_Width}
              autoClose={true}
              options={temperaments}
              text={temperaments.filter((v) => v.id === microSkillState.temperament)[0]?.display_text ?? 'Select Temperament'}
              onSelect={handleTemperamentChange}
            />
          </FormControl>

          {/* gender */}
          <div className={classes.radioWithLabel}>
            <Label labelText="Gender" required={true} p="0 0 0.8rem 0" />

            <div className={classes.genderRadioWrapper}>
              {microSkillState.withAvatar ? <span onClick={() => handleGenderChange('male')}>
                <Radio checked={microSkillState.gender === 'male'} />
                Male
              </span> : null}
              <span onClick={() => handleGenderChange('female')}>
                <Radio checked={microSkillState.gender === 'female'} />
                Female
              </span>
            </div>
          </div>

          <div className={classes.switchContainer}>
            <Label labelText="Avatar" />
            <ToggleSwitch
              checked={microSkillState.withAvatar}
              onChange={() => setMicroSkillState({ ...microSkillState, withAvatar: !microSkillState.withAvatar, gender: !microSkillState.withAvatar ? microSkillState.gender : 'female' })}
              name="Enable avatar"
            />
          </div>


        </div>

        <div className={`${classes.rightContainer} scrollbar`}>
          <div className={`${classes.promptContainerWrapper} scrollbar`}>
            {
              microSkillState.prompt.map((item, i) => (
                <div key={i} className={`${classes.promptItemWrapper}`}>
                  <Label labelText={item.label} required={true} p="0 0 0.8rem 0" />

                  <textarea
                    // className='scrollbar'
                    ref={(el) => promptRefs.current[i] = el}
                    disabled={item.disable}
                    value={item.content}
                    onChange={(e) => handlePromptChange(e, item)}
                  />
                </div>
              ))
            }
          </div>
        </div>


      </FlexRowDiv>
      <FormControl className={classes.action_btn_container}>
        <FlatClearButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
            marginRight: '2rem'
          }}
          onClick={() => {
            // saveDraft();
            setCurrentPage(pages[0]);
          }}>
          Back
        </FlatClearButton>
        <FlatPrimaryButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
          }}
          onClick={nextAction}>
          Next
        </FlatPrimaryButton>
      </FormControl>
    </>
  );
};
